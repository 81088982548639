export interface Contact {
  recordID: string; // ulong
  isActive: boolean;
  firstName: string; // string
  lastName: string; // string
  manualMarketingEMailConfirmationStatus: string; // string
  timeRegistrationEmailWasSent: string; // System.DateTime?
  marketingContactStatusSourceType: string; // string
  lastMarketingEmailName: string; // string
  status: string; // string
  registeredAt: string; // System.DateTime?
  utm_campaign: string; // string
  iLKuecheKontaktweitergabeAccepted: string; // string
  marketingEmailsOpened: number; // int?
  leadStatus: string; // string
  zRNummer_eigeneAuskunft: string; // string
  totalRevenue: string; // string
  marketingContactStatusSourceName: string; // string
  geschlecht: string; // string
  postalCode: string; // string
  iLKuecheWelcheMaterialienFürKüchenfrontenGefallenIhnenBesonders: string; // string
  gewinnspielFrage_DasWesentliche20212022: string; // string
  iLKuecheDatenschutzAccepted: false; // bool?
  timeToMoveFromMarketingQualifiedLeadToCustomer_HH_mm_ss: string; // string
  rolleVME_PIEX_Approved: false; // bool?
  emailHardBounceReason: string; // string
  marketingEmailsReplied: number; // int?
  marketingContactUntilNextUpdate: string; // string
  rollenBeiVMEHändlerbeirat: string; // string
  pIEXProduktbeschreibungServicebeschreibungAccepted: string; // string
  devAccount: string; // string
  vMELastUpdated: string; // System.DateTime?
  iPCity: string; // string
  optedOutOfEmail_MarketingInformation: string; // string
  companySize: string; // string
  iLKuecheWelcheFormSollIhreKücheHaben: string; // string
  invalidEmailAddress: false; // bool?
  emailAddressAutomatedQuarantineReason: string; // string
  lastTouchConvertingCampaign: string; // string
  rolleOnlineShopTeilnehmer_innen: false; // bool?
  firstTouchConvertingCampaign: string; // string
  recentDealCloseDate: string; // System.DateTime?
  numberOfPageviews: number; // int
  legalBasisForProcessingContactData: string; // string
  becameAMarketingQualifiedLeadDate: string; // System.DateTime?
  googleAdClickId: string; // string
  militaryStatus: string; // string
  grillAndChillGewinnWeberGasgrill27_05_16_06_2021: false; // bool?
  gewinnspielPartnermagazin: false; // bool?
  campaignOfLastBookingInMeetingsTool: string; // string
  buyingRole: string; // string
  rollenBeiVMEPositionImUnternehmen: string; // string
  timeOfLastSession: string; // System.DateTime?
  timeOfFirstSession: string; // System.DateTime?
  iLKuecheKuechenpartnerWaehlen: string; // string
  abteilungsbezeichnung: string; // string
  closeDate: string; // System.DateTime?
  fieldOfStudy: string; // string
  utm_medium: string; // string
  message: string; // string
  numberOfAssociatedDeals: number; // int?
  gewinnspielGrillAndChill: string; // string
  marketingContactStatus: string; // string
  recentDealAmount: number; // double?
  numberOfTimesContacted: number; // int?
  numberOfSalesActivities: number; // int?
  optedOutOfAllEmailFrom_Trendhopper: string; // string
  firstConversionDate: string; // System.DateTime?
  dateOfBirth: string; // System.DateTime?
  recentSalesEmailClickedDate: string; // System.DateTime?
  recentSalesEmailOpenedDate: string; // System.DateTime?
  utm_content: string; // string
  originalSource: string; // string
  optedOutOfEmail_HotNewsVonTrendhopper: false; // bool?
  firstMarketingEmailReplyDate: string; // System.DateTime?
  timeToMoveFromOpportunityToCustomer_HH_mm_ss: string; // string
  gewinnspielSamuHaber: false; // bool?
  optedOutOfEmailOneToOne: false; // bool?
  firstDealCreatedDate: string; // System.DateTime?
  numberOfFormSubmissions: number; // int
  currentlyInWorkflow: false; // bool?
  mediumOfLastBookingInMeetingsTool: string; // string
  contactUnworked: boolean; // bool
  rollenBeiVMEARBeirat: string; // string
  createDate: string; // System.DateTime
  firstConversion: string; // string
  becameASalesQualifiedLeadDate: string; // System.DateTime?
  dateOfLastMeetingBookedInMeetingsTool: string; // System.DateTime?
  iLKuecheWieGrossIstDerRaumFürIhreKüche: string; // string
  firstMarketingEmailClickDate: string; // System.DateTime?
  city: string; // string
  startDate: string; // System.DateTime?
  preferredLanguage: string; // string
  register_WiedervorlageGF: false; // bool?
  timeToMoveFromLeadToCustomer_HH_mm_ss: string; // string
  warengruppe: string; // string
  mobilePhoneNumber: string; // string
  microsoftID: string; // string
  numberOfEventCompletions: number; // int
  degree: string; // string
  utm_source: string; // string
  averagePageviews: number; // int
  lastSequenceEndedDate: string; // System.DateTime?
  marketingEmailsDelivered: number; // int?
  timeZone: string; // string
  numberOfUniqueFormsSubmitted: number; // int
  emailConfirmed: string; // string
  lastModifiedDate: string; // System.DateTime
  zielmarkt_B2B_B2C: string; // string
  azureServices: string; // string
  optedOutOfAllEmailFrom_Interliving: false; // bool?
  numberOfSessions: number; // int
  position_Seniorität: string; // string
  workEmail: string; // string
  iPCountryCode: string; // string
  phoneNumber: string; // string
  iLKuecheWelcheStilweltGefälltIhnen: string; // string
  becameASubscriberDate: string; // System.DateTime?
  lastEngagementDate: string; // System.DateTime?
  rolleBeiVMEMeinVMEGenehmigungen: false; // bool?
  lastSequenceEnrolledDate: string; // System.DateTime?
  iLKuecheEigeneAnmerkungenZuGewünschtenEigenschaften: string; // string
  lastNPSSurveyRating: string; // string
  iLKuecheILNewsletterAccepted: false; // bool?
  marketingEmailConfirmationStatus: string; // string
  contactOwner: string; // string
  eventRevenue: number; // double
  lastNPSSurveyDate: string; // System.DateTime?
  lastActivityDate: string; // System.DateTime?
  nextActivityDate: string; // System.DateTime?
  sourceOfLastBookingInMeetingsTool: string; // string
  optedOutOfEmailCustomerServiceCommunication: false; // bool?
  ownerAssignedDate: string; // System.DateTime?
  bestellungInterlivingKatalogeUMagazin: string; // string
  iLKuecheWannBenötigenSieEineMassgenauePlanung: string; // string
  stateRegion: string; // string
  becameAnOpportunityDate: string; // System.DateTime?
  marketingEmailsClicked: number; // int?
  optedOutOfEmailNL_Social_Media: false; // bool?
  lastMarketingEmailOpenDate: string; // System.DateTime?
  interlivingGewinnspielEBike: false; // bool?
  unsubscribedFromAllEmail: false; // bool?
  membershipNotes: string; // string
  maxProDSConsent_Auswahl: false; // bool?
  bereichAbteilung: string; // string
  rolleEinladungsverteiler: string; // string
  createdByUserID: string; // string
  lastPageSeen: string; // string
  firstPageSeen: string; // string
  beantragterAzureService: string; // string
  originalSourceDrillDown1: string; // string
  lastMarketingEmailSendDate: string; // System.DateTime?
  optedOutOfEmailNL_Chefbrief: false; // bool?
  iLKuecheWelcheEigenschaftenSindIhnenWichtig: string; // string
  optedOutOfEmailNL_EPR_AKTUELL: false; // bool?
  iPStateCodeRegionCode: string; // string
  recentConversionDate: string; // System.DateTime?
  becameAnOtherLifecycleDate: string; // System.DateTime?
  akadTitel: string; // string
  _2021_11AdventsGewinnspiel: string; // string
  originalSourceDrillDown2: string; // string
  lifecycleStage: string; // string
  optedOutOfEmailNL_Aufsichtsrat: false; // bool?
  optedOutOfEmailInterlivingB2CNewsletter: false; // bool?
  register_WiedervorlageSTD: false; // bool?
  school: string; // string
  lastContacted: string; // System.DateTime?
  streetAddress: string; // string
  vMEProductInfoServicebeschreibungAccepted: false; // bool?
  recentConversion: string; // string
  hubSpotTeam: string; // string
  optedOutOfEmailNL_IL_UM_Geschäftsführung: false; // bool?
  optedOutOfEmailNL_intern_VME: false; // bool?
  lastMarketingEmailReplyDate: string; // System.DateTime?
  optedOutOfEmailNL_IL_UM_Beauftragter: false; // bool?
  latestSourceDate: string; // System.DateTime?
  optedOutOfEmailNL_IL_UM_Mitarbeiter: false; // bool?
  likelihoodToClose: number; // double?
  optedOutOfEmailNL_B2B_Presse: false; // bool?
  countryRegion: string; // string
  numberOfSequencesEnrolled: number; // int?
  emailAddressQuarantineReason: string; // string
  gewinnspielFrage: string; // string
  optedOutOfEmailEinwilligungZuSpeicherung: false; // bool?
  rollenBeiVMEEinkaufsausschuss: string; // string
  optedOutOfEmail_VMECorporate: false; // bool?
  facebookClickId: string; // string
  lastMarketingEmailClickDate: string; // System.DateTime?
  persona: string; // string
  optedOutOfEmailNL_Corona: false; // bool?
  lastNPSSurveyComment: string; // string
  salutation: string; // string
  timeToMoveFromSubscriberToCustomer_HH_mm_ss: string; // string
  sendsSinceLastEngagement: number; // int?
  becameACustomerDate: string; // System.DateTime?
  iLKuecheWelcheFarbenGefallenIhnenBesonders: string; // string
  timeBetweenContactCreationAndDealClose_HH_mm_ss: string; // string
  optedOutOfEmailNL_EPR_Geschäftsführung: false; // bool?
  optedOutOfEmailNL_IL_WM_Geschäftsführung: false; // bool?
  optedOutOfEmailEinwilligungZurPersönlichenEmailKommunikation: false; // bool?
  kontaktDelta: false; // bool?
  currentlyInSequence: false; // bool?
  iPStateRegion: string; // string
  relationshipStatus: string; // string
  timeLastSeen: string; // System.DateTime?
  timeFirstSeen: string; // System.DateTime
  optedOutOfEmailNL_IL_WM_Mitarbeiter: false; // bool?
  jobFunction: string; // string
  becameALeadDate: string; // System.DateTime?
  recentSalesEmailRepliedDate: string; // System.DateTime?
  briefanrede: string; // string
  websiteURL: string; // string
  jobTitle: string; // string
  emailDomain: string; // string
  hubSpotScore: string; // string
  twitterUsername: string; // string
  optedOutOfEmailInterlivingB2CNewsletter_EPRVersion: false; // bool?
  adventsGewinnspielGewinn4erSetDekovasen_22_11_28_11_2021: false; // bool?
  iLKuecheWeitereAnmerkungen: string; // string
  firstReferringSite: string; // string
  lastReferringSite: string; // string
  rolleInterneITTechnischeZugriffe: string; // string
  oldHubSpotID: string; // string
  iLKuecheWieVielBudgetPlanenSieFürIhreNeueKücheEin: string; // string
  becameAnEvangelistDate: string; // System.DateTime?
  latestSourceDrillDown1: string; // string
  rollenBeiVMEAufsichtsrat: string; // string
  daysToClose: number; // int?
  timeToMoveFromSalesQualifiedLeadToCustomer_HH_mm_ss: string; // string
  email: string; // string
  utm_term: string; // string
  companyName: string; // string
  gewinnspielTeilnahmeDraußenZuhause: string; // string
  annualRevenue: string; // string
  adelstitel: string; // string
  latestSourceDrillDown2: string; // string
  maritalStatus: string; // string
  businessUnits: string; // string
  istILDashboardFreigabeberechtigtePersonMeinVMEGenehmigungen: string; // string
  firstMarketingEmailOpenDate: string; // System.DateTime?
  iPTimezone: string; // string
  faxNumber: string; // string
  timeBetweenContactCreationAndDealCreation_HH_mm_ss: string; // string
  iPCountry: string; // string
  seniority: string; // string
  industry: string; // string
  auswahl_temp: string; // string
  contactPriority: string; // string
  objectCreateDatetime: string; // System.DateTime?
  domainToWhichRegistrationEmailWasSent: string; // string
  rolleMeinVMEDigitaleProdukteBerechtigungen: string; // string
  ichHabeDieTermsAndConditionsGelesenUndAkzeptiereDiese: string; // string
  emailtestaccount: string; // string
  firstMarketingEmailSendDate: string; // System.DateTime?
  taipanKontakt: string; // string
  lastSequenceEnrolled: string; // string
  meinVMERegistrierungErfolgreich: false; // bool?
  emailAddressQuarantined: string; // string
  graduationDate: string; // System.DateTime?
  maxProConsentReason: string; // string
  numberOfEmployees: number; // int?
  grillAndChillGewinnGartenSitzgruppe_28_06_08_07_2021: false; // bool?
  marketingEmailsBounced: number; // int?
  latestSource: string; // string
  associatedCompany: string; // string
  associatedDeal: string; // string
  associatedTicket: string; // string
  associatedPayment: string; // string
  associatedSubscription: string; // string
  additionalEmailAddresses: string; // string
  associatedCompanyId: string; // string // !!! can be multiple values
  associatedDealIDs: string; // string
  associatedTicketIDs: string; // string
  associatedPaymentIDs: string; // string
  associatedSubscriptionIDs: string; // string

  isZrBiActive: boolean;
  contactPanelId: string;
  companyZrNumber: string;
  ilDashboardAzureServiceID: string; // NOT added this field in `toAPI` since its `Company` fields joined in ContactDto on the backend side
  contactAzureId: string;
}

export interface Value {
  value: Contact[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<Contact>): any {
  return {
    RecordID: data?.recordID || undefined,
    IsActive: data?.isActive || false,
    FirstName: data?.firstName || '',
    LastName: data?.lastName || '',
    ManualMarketingEMailConfirmationStatus: data?.manualMarketingEMailConfirmationStatus || '',
    TimeRegistrationEmailWasSent: data?.timeRegistrationEmailWasSent || '',
    MarketingContactStatusSourceType: data?.marketingContactStatusSourceType || '',
    LastMarketingEmailName: data?.lastMarketingEmailName || '',
    Status: data?.status || '',
    RegisteredAt: data?.registeredAt || '',
    Utm_campaign: data?.utm_campaign || '',
    ILKuecheKontaktweitergabeAccepted: data?.iLKuecheKontaktweitergabeAccepted || '',
    MarketingEmailsOpened: data?.marketingEmailsOpened || 0,
    LeadStatus: data?.leadStatus || '',
    ZRNummer_eigeneAuskunft: data?.zRNummer_eigeneAuskunft || '',
    TotalRevenue: data?.totalRevenue || '',
    MarketingContactStatusSourceName: data?.marketingContactStatusSourceName || '',
    Geschlecht: data?.geschlecht || '',
    PostalCode: data?.postalCode || '',
    ILKuecheWelcheMaterialienFürKüchenfrontenGefallenIhnenBesonders:
      data?.iLKuecheWelcheMaterialienFürKüchenfrontenGefallenIhnenBesonders || '',
    GewinnspielFrage_DasWesentliche20212022: data?.gewinnspielFrage_DasWesentliche20212022 || '',
    ILKuecheDatenschutzAccepted: data?.iLKuecheDatenschutzAccepted || false,
    TimeToMoveFromMarketingQualifiedLeadToCustomer_HH_mm_ss:
      data?.timeToMoveFromMarketingQualifiedLeadToCustomer_HH_mm_ss || '',
    RolleVME_PIEX_Approved: data?.rolleVME_PIEX_Approved || false,
    EmailHardBounceReason: data?.emailHardBounceReason || '',
    MarketingEmailsReplied: data?.marketingEmailsReplied || 0,
    MarketingContactUntilNextUpdate: data?.marketingContactUntilNextUpdate || '',
    RollenBeiVMEHändlerbeirat: data?.rollenBeiVMEHändlerbeirat || '',
    PIEXProduktbeschreibungServicebeschreibungAccepted: data?.pIEXProduktbeschreibungServicebeschreibungAccepted || '',
    devAccount: data?.devAccount || '',
    VMELastUpdated: data?.vMELastUpdated || '',
    IPCity: data?.iPCity || '',
    OptedOutOfEmail_MarketingInformation: data?.optedOutOfEmail_MarketingInformation || '',
    CompanySize: data?.companySize || '',
    ILKuecheWelcheFormSollIhreKücheHaben: data?.iLKuecheWelcheFormSollIhreKücheHaben || '',
    InvalidEmailAddress: data?.invalidEmailAddress || false,
    EmailAddressAutomatedQuarantineReason: data?.emailAddressAutomatedQuarantineReason || '',
    LastTouchConvertingCampaign: data?.lastTouchConvertingCampaign || '',
    RolleOnlineShopTeilnehmer_innen: data?.rolleOnlineShopTeilnehmer_innen || false,
    FirstTouchConvertingCampaign: data?.firstTouchConvertingCampaign || '',
    RecentDealCloseDate: data?.recentDealCloseDate || '',
    NumberOfPageviews: data?.numberOfPageviews || 0,
    LegalBasisForProcessingContactData: data?.legalBasisForProcessingContactData || '',
    BecameAMarketingQualifiedLeadDate: data?.becameAMarketingQualifiedLeadDate || '',
    GoogleAdClickId: data?.googleAdClickId || '',
    MilitaryStatus: data?.militaryStatus || '',
    GrillAndChillGewinnWeberGasgrill27_05_16_06_2021: data?.grillAndChillGewinnWeberGasgrill27_05_16_06_2021 || false,
    GewinnspielPartnermagazin: data?.gewinnspielPartnermagazin || false,
    CampaignOfLastBookingInMeetingsTool: data?.campaignOfLastBookingInMeetingsTool || '',
    BuyingRole: data?.buyingRole || '',
    RollenBeiVMEPositionImUnternehmen: data?.rollenBeiVMEPositionImUnternehmen || '',
    TimeOfLastSession: data?.timeOfLastSession || '',
    TimeOfFirstSession: data?.timeOfFirstSession || '',
    ILKuecheKuechenpartnerWaehlen: data?.iLKuecheKuechenpartnerWaehlen || '',
    Abteilungsbezeichnung: data?.abteilungsbezeichnung || '',
    CloseDate: data?.closeDate || '',
    FieldOfStudy: data?.fieldOfStudy || '',
    utm_medium: data?.utm_medium || '',
    Message: data?.message || '',
    NumberOfAssociatedDeals: data?.numberOfAssociatedDeals || 0,
    GewinnspielGrillAndChill: data?.gewinnspielGrillAndChill || '',
    MarketingContactStatus: data?.marketingContactStatus || '',
    RecentDealAmount: data?.recentDealAmount || 0,
    NumberOfTimesContacted: data?.numberOfTimesContacted || 0,
    NumberOfSalesActivities: data?.numberOfSalesActivities || 0,
    OptedOutOfAllEmailFrom_Trendhopper: data?.optedOutOfAllEmailFrom_Trendhopper || '',
    FirstConversionDate: data?.firstConversionDate || '',
    DateOfBirth: data?.dateOfBirth || '',
    RecentSalesEmailClickedDate: data?.recentSalesEmailClickedDate || '',
    RecentSalesEmailOpenedDate: data?.recentSalesEmailOpenedDate || '',
    utm_content: data?.utm_content || '',
    OriginalSource: data?.originalSource || '',
    OptedOutOfEmail_HotNewsVonTrendhopper: data?.optedOutOfEmail_HotNewsVonTrendhopper || false,
    FirstMarketingEmailReplyDate: data?.firstMarketingEmailReplyDate || '',
    TimeToMoveFromOpportunityToCustomer_HH_mm_ss: data?.timeToMoveFromOpportunityToCustomer_HH_mm_ss || '',
    GewinnspielSamuHaber: data?.gewinnspielSamuHaber || false,
    OptedOutOfEmailOneToOne: data?.optedOutOfEmailOneToOne || false,
    FirstDealCreatedDate: data?.firstDealCreatedDate || '',
    NumberOfFormSubmissions: data?.numberOfFormSubmissions || 0,
    CurrentlyInWorkflow: data?.currentlyInWorkflow || false,
    MediumOfLastBookingInMeetingsTool: data?.mediumOfLastBookingInMeetingsTool || '',
    ContactUnworked: data?.contactUnworked || false,
    RollenBeiVMEARBeirat: data?.rollenBeiVMEARBeirat || '',
    CreateDate: data?.createDate || '',
    FirstConversion: data?.firstConversion || '',
    BecameASalesQualifiedLeadDate: data?.becameASalesQualifiedLeadDate || '',
    DateOfLastMeetingBookedInMeetingsTool: data?.dateOfLastMeetingBookedInMeetingsTool || '',
    ILKuecheWieGrossIstDerRaumFürIhreKüche: data?.iLKuecheWieGrossIstDerRaumFürIhreKüche || '',
    FirstMarketingEmailClickDate: data?.firstMarketingEmailClickDate || '',
    City: data?.city || '',
    StartDate: data?.startDate || '',
    PreferredLanguage: data?.preferredLanguage || '',
    Register_WiedervorlageGF: data?.register_WiedervorlageGF || false,
    TimeToMoveFromLeadToCustomer_HH_mm_ss: data?.timeToMoveFromLeadToCustomer_HH_mm_ss || '',
    Warengruppe: data?.warengruppe || '',
    MobilePhoneNumber: data?.mobilePhoneNumber || '',
    MicrosoftID: data?.microsoftID || '',
    NumberOfEventCompletions: data?.numberOfEventCompletions || 0,
    Degree: data?.degree || '',
    utm_source: data?.utm_source || '',
    AveragePageviews: data?.averagePageviews || 0,
    LastSequenceEndedDate: data?.lastSequenceEndedDate || '',
    MarketingEmailsDelivered: data?.marketingEmailsDelivered || 0,
    TimeZone: data?.timeZone || '',
    NumberOfUniqueFormsSubmitted: data?.numberOfUniqueFormsSubmitted || 0,
    EmailConfirmed: data?.emailConfirmed || '',
    LastModifiedDate: data?.lastModifiedDate || '',
    Zielmarkt_B2B_B2C: data?.zielmarkt_B2B_B2C || '',
    AzureServices: data?.azureServices || '',
    OptedOutOfAllEmailFrom_Interliving: data?.optedOutOfAllEmailFrom_Interliving || false,
    NumberOfSessions: data?.numberOfSessions || 0,
    Position_Seniorität: data?.position_Seniorität || '',
    WorkEmail: data?.workEmail || '',
    IPCountryCode: data?.iPCountryCode || '',
    PhoneNumber: data?.phoneNumber || '',
    ILKuecheWelcheStilweltGefälltIhnen: data?.iLKuecheWelcheStilweltGefälltIhnen || '',
    BecameASubscriberDate: data?.becameASubscriberDate || '',
    LastEngagementDate: data?.lastEngagementDate || '',
    RolleBeiVMEMeinVMEGenehmigungen: data?.rolleBeiVMEMeinVMEGenehmigungen || false,
    LastSequenceEnrolledDate: data?.lastSequenceEnrolledDate || '',
    ILKuecheEigeneAnmerkungenZuGewünschtenEigenschaften:
      data?.iLKuecheEigeneAnmerkungenZuGewünschtenEigenschaften || '',
    LastNPSSurveyRating: data?.lastNPSSurveyRating || '',
    ILKuecheILNewsletterAccepted: data?.iLKuecheILNewsletterAccepted || false,
    MarketingEmailConfirmationStatus: data?.marketingEmailConfirmationStatus || '',
    ContactOwner: data?.contactOwner || '',
    EventRevenue: data?.eventRevenue || 0,
    LastNPSSurveyDate: data?.lastNPSSurveyDate || '',
    LastActivityDate: data?.lastActivityDate || '',
    NextActivityDate: data?.nextActivityDate || '',
    SourceOfLastBookingInMeetingsTool: data?.sourceOfLastBookingInMeetingsTool || '',
    OptedOutOfEmailCustomerServiceCommunication: data?.optedOutOfEmailCustomerServiceCommunication || false,
    OwnerAssignedDate: data?.ownerAssignedDate || '',
    BestellungInterlivingKatalogeUMagazin: data?.bestellungInterlivingKatalogeUMagazin || '',
    ILKuecheWannBenötigenSieEineMassgenauePlanung: data?.iLKuecheWannBenötigenSieEineMassgenauePlanung || '',
    StateRegion: data?.stateRegion || '',
    BecameAnOpportunityDate: data?.becameAnOpportunityDate || '',
    MarketingEmailsClicked: data?.marketingEmailsClicked || 0,
    OptedOutOfEmailNL_Social_Media: data?.optedOutOfEmailNL_Social_Media || false,
    LastMarketingEmailOpenDate: data?.lastMarketingEmailOpenDate || '',
    InterlivingGewinnspielEBike: data?.interlivingGewinnspielEBike || false,
    UnsubscribedFromAllEmail: data?.unsubscribedFromAllEmail || false,
    MembershipNotes: data?.membershipNotes || '',
    MaxProDSConsent_Auswahl: data?.maxProDSConsent_Auswahl || false,
    BereichAbteilung: data?.bereichAbteilung || '',
    RolleEinladungsverteiler: data?.rolleEinladungsverteiler || '',
    CreatedByUserID: data?.createdByUserID || '',
    LastPageSeen: data?.lastPageSeen || '',
    FirstPageSeen: data?.firstPageSeen || '',
    BeantragterAzureService: data?.beantragterAzureService || '',
    OriginalSourceDrillDown1: data?.originalSourceDrillDown1 || '',
    LastMarketingEmailSendDate: data?.lastMarketingEmailSendDate || '',
    OptedOutOfEmailNL_Chefbrief: data?.optedOutOfEmailNL_Chefbrief || false,
    ILKuecheWelcheEigenschaftenSindIhnenWichtig: data?.iLKuecheWelcheEigenschaftenSindIhnenWichtig || '',
    OptedOutOfEmailNL_EPR_AKTUELL: data?.optedOutOfEmailNL_EPR_AKTUELL || false,
    IPStateCodeRegionCode: data?.iPStateCodeRegionCode || '',
    RecentConversionDate: data?.recentConversionDate || '',
    BecameAnOtherLifecycleDate: data?.becameAnOtherLifecycleDate || '',
    AkadTitel: data?.akadTitel || '',
    _2021_11AdventsGewinnspiel: data?._2021_11AdventsGewinnspiel || '',
    OriginalSourceDrillDown2: data?.originalSourceDrillDown2 || '',
    LifecycleStage: data?.lifecycleStage || '',
    OptedOutOfEmailNL_Aufsichtsrat: data?.optedOutOfEmailNL_Aufsichtsrat || false,
    OptedOutOfEmailInterlivingB2CNewsletter: data?.optedOutOfEmailInterlivingB2CNewsletter || false,
    Register_WiedervorlageSTD: data?.register_WiedervorlageSTD || false,
    School: data?.school || '',
    LastContacted: data?.lastContacted || '',
    StreetAddress: data?.streetAddress || '',
    VMEProductInfoServicebeschreibungAccepted: data?.vMEProductInfoServicebeschreibungAccepted || false,
    RecentConversion: data?.recentConversion || '',
    HubSpotTeam: data?.hubSpotTeam || '',
    OptedOutOfEmailNL_IL_UM_Geschäftsführung: data?.optedOutOfEmailNL_IL_UM_Geschäftsführung || false,
    OptedOutOfEmailNL_intern_VME: data?.optedOutOfEmailNL_intern_VME || false,
    LastMarketingEmailReplyDate: data?.lastMarketingEmailReplyDate || '',
    OptedOutOfEmailNL_IL_UM_Beauftragter: data?.optedOutOfEmailNL_IL_UM_Beauftragter || false,
    LatestSourceDate: data?.latestSourceDate || '',
    OptedOutOfEmailNL_IL_UM_Mitarbeiter: data?.optedOutOfEmailNL_IL_UM_Mitarbeiter || false,
    LikelihoodToClose: data?.likelihoodToClose || 0,
    OptedOutOfEmailNL_B2B_Presse: data?.optedOutOfEmailNL_B2B_Presse || false,
    CountryRegion: data?.countryRegion || '',
    NumberOfSequencesEnrolled: data?.numberOfSequencesEnrolled || 0,
    EmailAddressQuarantineReason: data?.emailAddressQuarantineReason || '',
    GewinnspielFrage: data?.gewinnspielFrage || '',
    OptedOutOfEmailEinwilligungZuSpeicherung: data?.optedOutOfEmailEinwilligungZuSpeicherung || false,
    RollenBeiVMEEinkaufsausschuss: data?.rollenBeiVMEEinkaufsausschuss || '',
    OptedOutOfEmail_VMECorporate: data?.optedOutOfEmail_VMECorporate || false,
    FacebookClickId: data?.facebookClickId || '',
    LastMarketingEmailClickDate: data?.lastMarketingEmailClickDate || '',
    Persona: data?.persona || '',
    OptedOutOfEmailNL_Corona: data?.optedOutOfEmailNL_Corona || false,
    LastNPSSurveyComment: data?.lastNPSSurveyComment || '',
    Salutation: data?.salutation || '',
    TimeToMoveFromSubscriberToCustomer_HH_mm_ss: data?.timeToMoveFromSubscriberToCustomer_HH_mm_ss || '',
    SendsSinceLastEngagement: data?.sendsSinceLastEngagement || 0,
    BecameACustomerDate: data?.becameACustomerDate || '',
    ILKuecheWelcheFarbenGefallenIhnenBesonders: data?.iLKuecheWelcheFarbenGefallenIhnenBesonders || '',
    TimeBetweenContactCreationAndDealClose_HH_mm_ss: data?.timeBetweenContactCreationAndDealClose_HH_mm_ss || '',
    OptedOutOfEmailNL_EPR_Geschäftsführung: data?.optedOutOfEmailNL_EPR_Geschäftsführung || false,
    OptedOutOfEmailNL_IL_WM_Geschäftsführung: data?.optedOutOfEmailNL_IL_WM_Geschäftsführung || false,
    OptedOutOfEmailEinwilligungZurPersönlichenEmailKommunikation:
      data?.optedOutOfEmailEinwilligungZurPersönlichenEmailKommunikation || false,
    KontaktDelta: data?.kontaktDelta || false,
    CurrentlyInSequence: data?.currentlyInSequence || false,
    IPStateRegion: data?.iPStateRegion || '',
    RelationshipStatus: data?.relationshipStatus || '',
    TimeLastSeen: data?.timeLastSeen || '',
    TimeFirstSeen: data?.timeFirstSeen || '',
    OptedOutOfEmailNL_IL_WM_Mitarbeiter: data?.optedOutOfEmailNL_IL_WM_Mitarbeiter || false,
    JobFunction: data?.jobFunction || '',
    BecameALeadDate: data?.becameALeadDate || '',
    RecentSalesEmailRepliedDate: data?.recentSalesEmailRepliedDate || '',
    Briefanrede: data?.briefanrede || '',
    WebsiteURL: data?.websiteURL || '',
    JobTitle: data?.jobTitle || '',
    EmailDomain: data?.emailDomain || '',
    HubSpotScore: data?.hubSpotScore || '',
    TwitterUsername: data?.twitterUsername || '',
    OptedOutOfEmailInterlivingB2CNewsletter_EPRVersion:
      data?.optedOutOfEmailInterlivingB2CNewsletter_EPRVersion || false,
    AdventsGewinnspielGewinn4erSetDekovasen_22_11_28_11_2021:
      data?.adventsGewinnspielGewinn4erSetDekovasen_22_11_28_11_2021 || false,
    ILKuecheWeitereAnmerkungen: data?.iLKuecheWeitereAnmerkungen || '',
    FirstReferringSite: data?.firstReferringSite || '',
    LastReferringSite: data?.lastReferringSite || '',
    RolleInterneITTechnischeZugriffe: data?.rolleInterneITTechnischeZugriffe || '',
    OldHubSpotID: data?.oldHubSpotID || '',
    ILKuecheWieVielBudgetPlanenSieFürIhreNeueKücheEin: data?.iLKuecheWieVielBudgetPlanenSieFürIhreNeueKücheEin || '',
    BecameAnEvangelistDate: data?.becameAnEvangelistDate || '',
    LatestSourceDrillDown1: data?.latestSourceDrillDown1 || '',
    RollenBeiVMEAufsichtsrat: data?.rollenBeiVMEAufsichtsrat || '',
    DaysToClose: data?.daysToClose || 0,
    TimeToMoveFromSalesQualifiedLeadToCustomer_HH_mm_ss:
      data?.timeToMoveFromSalesQualifiedLeadToCustomer_HH_mm_ss || '',
    Email: data?.email || '',
    utm_term: data?.utm_term || '',
    CompanyName: data?.companyName || '',
    GewinnspielTeilnahmeDraußenZuhause: data?.gewinnspielTeilnahmeDraußenZuhause || '',
    AnnualRevenue: data?.annualRevenue || '',
    Adelstitel: data?.adelstitel || '',
    LatestSourceDrillDown2: data?.latestSourceDrillDown2 || '',
    MaritalStatus: data?.maritalStatus || '',
    BusinessUnits: data?.businessUnits || '',
    IstILDashboardFreigabeberechtigtePersonMeinVMEGenehmigungen:
      data?.istILDashboardFreigabeberechtigtePersonMeinVMEGenehmigungen || '',
    FirstMarketingEmailOpenDate: data?.firstMarketingEmailOpenDate || '',
    IPTimezone: data?.iPTimezone || '',
    FaxNumber: data?.faxNumber || '',
    TimeBetweenContactCreationAndDealCreation_HH_mm_ss: data?.timeBetweenContactCreationAndDealCreation_HH_mm_ss || '',
    IPCountry: data?.iPCountry || '',
    Seniority: data?.seniority || '',
    Industry: data?.industry || '',
    Auswahl_temp: data?.auswahl_temp || '',
    ContactPriority: data?.contactPriority || '',
    ObjectCreateDatetime: data?.objectCreateDatetime || '',
    DomainToWhichRegistrationEmailWasSent: data?.domainToWhichRegistrationEmailWasSent || '',
    RolleMeinVMEDigitaleProdukteBerechtigungen: data?.rolleMeinVMEDigitaleProdukteBerechtigungen || '',
    IchHabeDieTermsAndConditionsGelesenUndAkzeptiereDiese:
      data?.ichHabeDieTermsAndConditionsGelesenUndAkzeptiereDiese || '',
    emailtestaccount: data?.emailtestaccount || '',
    FirstMarketingEmailSendDate: data?.firstMarketingEmailSendDate || '',
    TaipanKontakt: data?.taipanKontakt || '',
    LastSequenceEnrolled: data?.lastSequenceEnrolled || '',
    meinVMERegistrierungErfolgreich: data?.meinVMERegistrierungErfolgreich || false,
    EmailAddressQuarantined: data?.emailAddressQuarantined || '',
    GraduationDate: data?.graduationDate || '',
    MaxProConsentReason: data?.maxProConsentReason || '',
    NumberOfEmployees: data?.numberOfEmployees || 0,
    GrillAndChillGewinnGartenSitzgruppe_28_06_08_07_2021:
      data?.grillAndChillGewinnGartenSitzgruppe_28_06_08_07_2021 || false,
    MarketingEmailsBounced: data?.marketingEmailsBounced || 0,
    LatestSource: data?.latestSource || '',
    AssociatedCompany: data?.associatedCompany || '',
    AssociatedDeal: data?.associatedDeal || '',
    AssociatedTicket: data?.associatedTicket || '',
    AssociatedPayment: data?.associatedPayment || '',
    AssociatedSubscription: data?.associatedSubscription || '',
    AdditionalEmailAddresses: data?.additionalEmailAddresses || '',
    AssociatedCompanyId: data?.associatedCompanyId || '',
    AssociatedDealIDs: data?.associatedDealIDs || '',
    AssociatedTicketIDs: data?.associatedTicketIDs || '',
    AssociatedPaymentIDs: data?.associatedPaymentIDs || '',
    AssociatedSubscriptionIDs: data?.associatedSubscriptionIDs || '',

    IsZrBiActive: data?.isZrBiActive || false,
    ContactPanelId: data?.contactPanelId || undefined,
    CompanyZrNumber: data?.companyZrNumber || undefined,
    ContactAzureId: data?.contactAzureId || undefined,
  };
}

function parse(data?: Partial<Contact>): Contact {
  return {
    recordID: data?.recordID || '',
    isActive: data?.isActive || false,
    firstName: data?.firstName || '',
    lastName: data?.lastName || '',
    manualMarketingEMailConfirmationStatus: data?.manualMarketingEMailConfirmationStatus || '',
    timeRegistrationEmailWasSent: data?.timeRegistrationEmailWasSent || '',
    marketingContactStatusSourceType: data?.marketingContactStatusSourceType || '',
    lastMarketingEmailName: data?.lastMarketingEmailName || '',
    status: data?.status || '',
    registeredAt: data?.registeredAt || '',
    utm_campaign: data?.utm_campaign || '',
    iLKuecheKontaktweitergabeAccepted: data?.iLKuecheKontaktweitergabeAccepted || '',
    marketingEmailsOpened: data?.marketingEmailsOpened || 0,
    leadStatus: data?.leadStatus || '',
    zRNummer_eigeneAuskunft: data?.zRNummer_eigeneAuskunft || '',
    totalRevenue: data?.totalRevenue || '',
    marketingContactStatusSourceName: data?.marketingContactStatusSourceName || '',
    geschlecht: data?.geschlecht || '',
    postalCode: data?.postalCode || '',
    iLKuecheWelcheMaterialienFürKüchenfrontenGefallenIhnenBesonders:
      data?.iLKuecheWelcheMaterialienFürKüchenfrontenGefallenIhnenBesonders || '',
    gewinnspielFrage_DasWesentliche20212022: data?.gewinnspielFrage_DasWesentliche20212022 || '',
    iLKuecheDatenschutzAccepted: data?.iLKuecheDatenschutzAccepted || false,
    timeToMoveFromMarketingQualifiedLeadToCustomer_HH_mm_ss:
      data?.timeToMoveFromMarketingQualifiedLeadToCustomer_HH_mm_ss || '',
    rolleVME_PIEX_Approved: data?.rolleVME_PIEX_Approved || false,
    emailHardBounceReason: data?.emailHardBounceReason || '',
    marketingEmailsReplied: data?.marketingEmailsReplied || 0,
    marketingContactUntilNextUpdate: data?.marketingContactUntilNextUpdate || '',
    rollenBeiVMEHändlerbeirat: data?.rollenBeiVMEHändlerbeirat || '',
    pIEXProduktbeschreibungServicebeschreibungAccepted: data?.pIEXProduktbeschreibungServicebeschreibungAccepted || '',
    devAccount: data?.devAccount || '',
    vMELastUpdated: data?.vMELastUpdated || '',
    iPCity: data?.iPCity || '',
    optedOutOfEmail_MarketingInformation: data?.optedOutOfEmail_MarketingInformation || '',
    companySize: data?.companySize || '',
    iLKuecheWelcheFormSollIhreKücheHaben: data?.iLKuecheWelcheFormSollIhreKücheHaben || '',
    invalidEmailAddress: data?.invalidEmailAddress || false,
    emailAddressAutomatedQuarantineReason: data?.emailAddressAutomatedQuarantineReason || '',
    lastTouchConvertingCampaign: data?.lastTouchConvertingCampaign || '',
    rolleOnlineShopTeilnehmer_innen: data?.rolleOnlineShopTeilnehmer_innen || false,
    firstTouchConvertingCampaign: data?.firstTouchConvertingCampaign || '',
    recentDealCloseDate: data?.recentDealCloseDate || '',
    numberOfPageviews: data?.numberOfPageviews || 0,
    legalBasisForProcessingContactData: data?.legalBasisForProcessingContactData || '',
    becameAMarketingQualifiedLeadDate: data?.becameAMarketingQualifiedLeadDate || '',
    googleAdClickId: data?.googleAdClickId || '',
    militaryStatus: data?.militaryStatus || '',
    grillAndChillGewinnWeberGasgrill27_05_16_06_2021: data?.grillAndChillGewinnWeberGasgrill27_05_16_06_2021 || false,
    gewinnspielPartnermagazin: data?.gewinnspielPartnermagazin || false,
    campaignOfLastBookingInMeetingsTool: data?.campaignOfLastBookingInMeetingsTool || '',
    buyingRole: data?.buyingRole || '',
    rollenBeiVMEPositionImUnternehmen: data?.rollenBeiVMEPositionImUnternehmen || '',
    timeOfLastSession: data?.timeOfLastSession || '',
    timeOfFirstSession: data?.timeOfFirstSession || '',
    iLKuecheKuechenpartnerWaehlen: data?.iLKuecheKuechenpartnerWaehlen || '',
    abteilungsbezeichnung: data?.abteilungsbezeichnung || '',
    closeDate: data?.closeDate || '',
    fieldOfStudy: data?.fieldOfStudy || '',
    utm_medium: data?.utm_medium || '',
    message: data?.message || '',
    numberOfAssociatedDeals: data?.numberOfAssociatedDeals || 0,
    gewinnspielGrillAndChill: data?.gewinnspielGrillAndChill || '',
    marketingContactStatus: data?.marketingContactStatus || '',
    recentDealAmount: data?.recentDealAmount || 0,
    numberOfTimesContacted: data?.numberOfTimesContacted || 0,
    numberOfSalesActivities: data?.numberOfSalesActivities || 0,
    optedOutOfAllEmailFrom_Trendhopper: data?.optedOutOfAllEmailFrom_Trendhopper || '',
    firstConversionDate: data?.firstConversionDate || '',
    dateOfBirth: data?.dateOfBirth || '',
    recentSalesEmailClickedDate: data?.recentSalesEmailClickedDate || '',
    recentSalesEmailOpenedDate: data?.recentSalesEmailOpenedDate || '',
    utm_content: data?.utm_content || '',
    originalSource: data?.originalSource || '',
    optedOutOfEmail_HotNewsVonTrendhopper: data?.optedOutOfEmail_HotNewsVonTrendhopper || false,
    firstMarketingEmailReplyDate: data?.firstMarketingEmailReplyDate || '',
    timeToMoveFromOpportunityToCustomer_HH_mm_ss: data?.timeToMoveFromOpportunityToCustomer_HH_mm_ss || '',
    gewinnspielSamuHaber: data?.gewinnspielSamuHaber || false,
    optedOutOfEmailOneToOne: data?.optedOutOfEmailOneToOne || false,
    firstDealCreatedDate: data?.firstDealCreatedDate || '',
    numberOfFormSubmissions: data?.numberOfFormSubmissions || 0,
    currentlyInWorkflow: data?.currentlyInWorkflow || false,
    mediumOfLastBookingInMeetingsTool: data?.mediumOfLastBookingInMeetingsTool || '',
    contactUnworked: data?.contactUnworked || false,
    rollenBeiVMEARBeirat: data?.rollenBeiVMEARBeirat || '',
    createDate: data?.createDate || '',
    firstConversion: data?.firstConversion || '',
    becameASalesQualifiedLeadDate: data?.becameASalesQualifiedLeadDate || '',
    dateOfLastMeetingBookedInMeetingsTool: data?.dateOfLastMeetingBookedInMeetingsTool || '',
    iLKuecheWieGrossIstDerRaumFürIhreKüche: data?.iLKuecheWieGrossIstDerRaumFürIhreKüche || '',
    firstMarketingEmailClickDate: data?.firstMarketingEmailClickDate || '',
    city: data?.city || '',
    startDate: data?.startDate || '',
    preferredLanguage: data?.preferredLanguage || '',
    register_WiedervorlageGF: data?.register_WiedervorlageGF || false,
    timeToMoveFromLeadToCustomer_HH_mm_ss: data?.timeToMoveFromLeadToCustomer_HH_mm_ss || '',
    warengruppe: data?.warengruppe || '',
    mobilePhoneNumber: data?.mobilePhoneNumber || '',
    microsoftID: data?.microsoftID || '',
    numberOfEventCompletions: data?.numberOfEventCompletions || 0,
    degree: data?.degree || '',
    utm_source: data?.utm_source || '',
    averagePageviews: data?.averagePageviews || 0,
    lastSequenceEndedDate: data?.lastSequenceEndedDate || '',
    marketingEmailsDelivered: data?.marketingEmailsDelivered || 0,
    timeZone: data?.timeZone || '',
    numberOfUniqueFormsSubmitted: data?.numberOfUniqueFormsSubmitted || 0,
    emailConfirmed: data?.emailConfirmed || '',
    lastModifiedDate: data?.lastModifiedDate || '',
    zielmarkt_B2B_B2C: data?.zielmarkt_B2B_B2C || '',
    azureServices: data?.azureServices || '',
    optedOutOfAllEmailFrom_Interliving: data?.optedOutOfAllEmailFrom_Interliving || false,
    numberOfSessions: data?.numberOfSessions || 0,
    position_Seniorität: data?.position_Seniorität || '',
    workEmail: data?.workEmail || '',
    iPCountryCode: data?.iPCountryCode || '',
    phoneNumber: data?.phoneNumber || '',
    iLKuecheWelcheStilweltGefälltIhnen: data?.iLKuecheWelcheStilweltGefälltIhnen || '',
    becameASubscriberDate: data?.becameASubscriberDate || '',
    lastEngagementDate: data?.lastEngagementDate || '',
    rolleBeiVMEMeinVMEGenehmigungen: data?.rolleBeiVMEMeinVMEGenehmigungen || false,
    lastSequenceEnrolledDate: data?.lastSequenceEnrolledDate || '',
    iLKuecheEigeneAnmerkungenZuGewünschtenEigenschaften:
      data?.iLKuecheEigeneAnmerkungenZuGewünschtenEigenschaften || '',
    lastNPSSurveyRating: data?.lastNPSSurveyRating || '',
    iLKuecheILNewsletterAccepted: data?.iLKuecheILNewsletterAccepted || false,
    marketingEmailConfirmationStatus: data?.marketingEmailConfirmationStatus || '',
    contactOwner: data?.contactOwner || '',
    eventRevenue: data?.eventRevenue || 0,
    lastNPSSurveyDate: data?.lastNPSSurveyDate || '',
    lastActivityDate: data?.lastActivityDate || '',
    nextActivityDate: data?.nextActivityDate || '',
    sourceOfLastBookingInMeetingsTool: data?.sourceOfLastBookingInMeetingsTool || '',
    optedOutOfEmailCustomerServiceCommunication: data?.optedOutOfEmailCustomerServiceCommunication || false,
    ownerAssignedDate: data?.ownerAssignedDate || '',
    bestellungInterlivingKatalogeUMagazin: data?.bestellungInterlivingKatalogeUMagazin || '',
    iLKuecheWannBenötigenSieEineMassgenauePlanung: data?.iLKuecheWannBenötigenSieEineMassgenauePlanung || '',
    stateRegion: data?.stateRegion || '',
    becameAnOpportunityDate: data?.becameAnOpportunityDate || '',
    marketingEmailsClicked: data?.marketingEmailsClicked || 0,
    optedOutOfEmailNL_Social_Media: data?.optedOutOfEmailNL_Social_Media || false,
    lastMarketingEmailOpenDate: data?.lastMarketingEmailOpenDate || '',
    interlivingGewinnspielEBike: data?.interlivingGewinnspielEBike || false,
    unsubscribedFromAllEmail: data?.unsubscribedFromAllEmail || false,
    membershipNotes: data?.membershipNotes || '',
    maxProDSConsent_Auswahl: data?.maxProDSConsent_Auswahl || false,
    bereichAbteilung: data?.bereichAbteilung || '',
    rolleEinladungsverteiler: data?.rolleEinladungsverteiler || '',
    createdByUserID: data?.createdByUserID || '',
    lastPageSeen: data?.lastPageSeen || '',
    firstPageSeen: data?.firstPageSeen || '',
    beantragterAzureService: data?.beantragterAzureService || '',
    originalSourceDrillDown1: data?.originalSourceDrillDown1 || '',
    lastMarketingEmailSendDate: data?.lastMarketingEmailSendDate || '',
    optedOutOfEmailNL_Chefbrief: data?.optedOutOfEmailNL_Chefbrief || false,
    iLKuecheWelcheEigenschaftenSindIhnenWichtig: data?.iLKuecheWelcheEigenschaftenSindIhnenWichtig || '',
    optedOutOfEmailNL_EPR_AKTUELL: data?.optedOutOfEmailNL_EPR_AKTUELL || false,
    iPStateCodeRegionCode: data?.iPStateCodeRegionCode || '',
    recentConversionDate: data?.recentConversionDate || '',
    becameAnOtherLifecycleDate: data?.becameAnOtherLifecycleDate || '',
    akadTitel: data?.akadTitel || '',
    _2021_11AdventsGewinnspiel: data?._2021_11AdventsGewinnspiel || '',
    originalSourceDrillDown2: data?.originalSourceDrillDown2 || '',
    lifecycleStage: data?.lifecycleStage || '',
    optedOutOfEmailNL_Aufsichtsrat: data?.optedOutOfEmailNL_Aufsichtsrat || false,
    optedOutOfEmailInterlivingB2CNewsletter: data?.optedOutOfEmailInterlivingB2CNewsletter || false,
    register_WiedervorlageSTD: data?.register_WiedervorlageSTD || false,
    school: data?.school || '',
    lastContacted: data?.lastContacted || '',
    streetAddress: data?.streetAddress || '',
    vMEProductInfoServicebeschreibungAccepted: data?.vMEProductInfoServicebeschreibungAccepted || false,
    recentConversion: data?.recentConversion || '',
    hubSpotTeam: data?.hubSpotTeam || '',
    optedOutOfEmailNL_IL_UM_Geschäftsführung: data?.optedOutOfEmailNL_IL_UM_Geschäftsführung || false,
    optedOutOfEmailNL_intern_VME: data?.optedOutOfEmailNL_intern_VME || false,
    lastMarketingEmailReplyDate: data?.lastMarketingEmailReplyDate || '',
    optedOutOfEmailNL_IL_UM_Beauftragter: data?.optedOutOfEmailNL_IL_UM_Beauftragter || false,
    latestSourceDate: data?.latestSourceDate || '',
    optedOutOfEmailNL_IL_UM_Mitarbeiter: data?.optedOutOfEmailNL_IL_UM_Mitarbeiter || false,
    likelihoodToClose: data?.likelihoodToClose || 0,
    optedOutOfEmailNL_B2B_Presse: data?.optedOutOfEmailNL_B2B_Presse || false,
    countryRegion: data?.countryRegion || '',
    numberOfSequencesEnrolled: data?.numberOfSequencesEnrolled || 0,
    emailAddressQuarantineReason: data?.emailAddressQuarantineReason || '',
    gewinnspielFrage: data?.gewinnspielFrage || '',
    optedOutOfEmailEinwilligungZuSpeicherung: data?.optedOutOfEmailEinwilligungZuSpeicherung || false,
    rollenBeiVMEEinkaufsausschuss: data?.rollenBeiVMEEinkaufsausschuss || '',
    optedOutOfEmail_VMECorporate: data?.optedOutOfEmail_VMECorporate || false,
    facebookClickId: data?.facebookClickId || '',
    lastMarketingEmailClickDate: data?.lastMarketingEmailClickDate || '',
    persona: data?.persona || '',
    optedOutOfEmailNL_Corona: data?.optedOutOfEmailNL_Corona || false,
    lastNPSSurveyComment: data?.lastNPSSurveyComment || '',
    salutation: data?.salutation || '',
    timeToMoveFromSubscriberToCustomer_HH_mm_ss: data?.timeToMoveFromSubscriberToCustomer_HH_mm_ss || '',
    sendsSinceLastEngagement: data?.sendsSinceLastEngagement || 0,
    becameACustomerDate: data?.becameACustomerDate || '',
    iLKuecheWelcheFarbenGefallenIhnenBesonders: data?.iLKuecheWelcheFarbenGefallenIhnenBesonders || '',
    timeBetweenContactCreationAndDealClose_HH_mm_ss: data?.timeBetweenContactCreationAndDealClose_HH_mm_ss || '',
    optedOutOfEmailNL_EPR_Geschäftsführung: data?.optedOutOfEmailNL_EPR_Geschäftsführung || false,
    optedOutOfEmailNL_IL_WM_Geschäftsführung: data?.optedOutOfEmailNL_IL_WM_Geschäftsführung || false,
    optedOutOfEmailEinwilligungZurPersönlichenEmailKommunikation:
      data?.optedOutOfEmailEinwilligungZurPersönlichenEmailKommunikation || false,
    kontaktDelta: data?.kontaktDelta || false,
    currentlyInSequence: data?.currentlyInSequence || false,
    iPStateRegion: data?.iPStateRegion || '',
    relationshipStatus: data?.relationshipStatus || '',
    timeLastSeen: data?.timeLastSeen || '',
    timeFirstSeen: data?.timeFirstSeen || '',
    optedOutOfEmailNL_IL_WM_Mitarbeiter: data?.optedOutOfEmailNL_IL_WM_Mitarbeiter || false,
    jobFunction: data?.jobFunction || '',
    becameALeadDate: data?.becameALeadDate || '',
    recentSalesEmailRepliedDate: data?.recentSalesEmailRepliedDate || '',
    briefanrede: data?.briefanrede || '',
    websiteURL: data?.websiteURL || '',
    jobTitle: data?.jobTitle || '',
    emailDomain: data?.emailDomain || '',
    hubSpotScore: data?.hubSpotScore || '',
    twitterUsername: data?.twitterUsername || '',
    optedOutOfEmailInterlivingB2CNewsletter_EPRVersion:
      data?.optedOutOfEmailInterlivingB2CNewsletter_EPRVersion || false,
    adventsGewinnspielGewinn4erSetDekovasen_22_11_28_11_2021:
      data?.adventsGewinnspielGewinn4erSetDekovasen_22_11_28_11_2021 || false,
    iLKuecheWeitereAnmerkungen: data?.iLKuecheWeitereAnmerkungen || '',
    firstReferringSite: data?.firstReferringSite || '',
    lastReferringSite: data?.lastReferringSite || '',
    rolleInterneITTechnischeZugriffe: data?.rolleInterneITTechnischeZugriffe || '',
    oldHubSpotID: data?.oldHubSpotID || '',
    iLKuecheWieVielBudgetPlanenSieFürIhreNeueKücheEin: data?.iLKuecheWieVielBudgetPlanenSieFürIhreNeueKücheEin || '',
    becameAnEvangelistDate: data?.becameAnEvangelistDate || '',
    latestSourceDrillDown1: data?.latestSourceDrillDown1 || '',
    rollenBeiVMEAufsichtsrat: data?.rollenBeiVMEAufsichtsrat || '',
    daysToClose: data?.daysToClose || 0,
    timeToMoveFromSalesQualifiedLeadToCustomer_HH_mm_ss:
      data?.timeToMoveFromSalesQualifiedLeadToCustomer_HH_mm_ss || '',
    email: data?.email || '',
    utm_term: data?.utm_term || '',
    companyName: data?.companyName || '',
    gewinnspielTeilnahmeDraußenZuhause: data?.gewinnspielTeilnahmeDraußenZuhause || '',
    annualRevenue: data?.annualRevenue || '',
    adelstitel: data?.adelstitel || '',
    latestSourceDrillDown2: data?.latestSourceDrillDown2 || '',
    maritalStatus: data?.maritalStatus || '',
    businessUnits: data?.businessUnits || '',
    istILDashboardFreigabeberechtigtePersonMeinVMEGenehmigungen:
      data?.istILDashboardFreigabeberechtigtePersonMeinVMEGenehmigungen || '',
    firstMarketingEmailOpenDate: data?.firstMarketingEmailOpenDate || '',
    iPTimezone: data?.iPTimezone || '',
    faxNumber: data?.faxNumber || '',
    timeBetweenContactCreationAndDealCreation_HH_mm_ss: data?.timeBetweenContactCreationAndDealCreation_HH_mm_ss || '',
    iPCountry: data?.iPCountry || '',
    seniority: data?.seniority || '',
    industry: data?.industry || '',
    auswahl_temp: data?.auswahl_temp || '',
    contactPriority: data?.contactPriority || '',
    objectCreateDatetime: data?.objectCreateDatetime || '',
    domainToWhichRegistrationEmailWasSent: data?.domainToWhichRegistrationEmailWasSent || '',
    rolleMeinVMEDigitaleProdukteBerechtigungen: data?.rolleMeinVMEDigitaleProdukteBerechtigungen || '',
    ichHabeDieTermsAndConditionsGelesenUndAkzeptiereDiese:
      data?.ichHabeDieTermsAndConditionsGelesenUndAkzeptiereDiese || '',
    emailtestaccount: data?.emailtestaccount || '',
    firstMarketingEmailSendDate: data?.firstMarketingEmailSendDate || '',
    taipanKontakt: data?.taipanKontakt || '',
    lastSequenceEnrolled: data?.lastSequenceEnrolled || '',
    meinVMERegistrierungErfolgreich: data?.meinVMERegistrierungErfolgreich || false,
    emailAddressQuarantined: data?.emailAddressQuarantined || '',
    graduationDate: data?.graduationDate || '',
    maxProConsentReason: data?.maxProConsentReason || '',
    numberOfEmployees: data?.numberOfEmployees || 0,
    grillAndChillGewinnGartenSitzgruppe_28_06_08_07_2021:
      data?.grillAndChillGewinnGartenSitzgruppe_28_06_08_07_2021 || false,
    marketingEmailsBounced: data?.marketingEmailsBounced || 0,
    latestSource: data?.latestSource || '',
    associatedCompany: data?.associatedCompany || '',
    associatedDeal: data?.associatedDeal || '',
    associatedTicket: data?.associatedTicket || '',
    associatedPayment: data?.associatedPayment || '',
    associatedSubscription: data?.associatedSubscription || '',
    additionalEmailAddresses: data?.additionalEmailAddresses || '',
    associatedCompanyId: data?.associatedCompanyId || '',
    associatedDealIDs: data?.associatedDealIDs || '',
    associatedTicketIDs: data?.associatedTicketIDs || '',
    associatedPaymentIDs: data?.associatedPaymentIDs || '',
    associatedSubscriptionIDs: data?.associatedSubscriptionIDs || '',

    isZrBiActive: data?.isZrBiActive || false,
    contactPanelId: data?.contactPanelId || '',
    companyZrNumber: data?.companyZrNumber || '',
    ilDashboardAzureServiceID: data?.ilDashboardAzureServiceID || '',
    contactAzureId: data?.contactAzureId || '',
  };
}

export default {
  parse,
  toAPI: toAPI,
};
