/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Prop, Vue } from 'vue-property-decorator';

import { Logger } from 'fsts';
import { namespace } from 'vuex-class';
import { Contact } from '@/shared/model/contact';
import ModuleRoleTable from './module-role-table/module-role-table.vue';

const logger = new Logger('contact-panel');
const contactModule = namespace('contact');
const contactPanelModule = namespace('contactPanel');
const gspRoleModule = namespace('gspRole');
const gspUserRoleModule = namespace('gspUserRole');
const gspServiceModule = namespace('gspService');

@Component({
  components: {ModuleRoleTable},
})
export default class ContactPanelView extends Vue {
  @gspServiceModule.Action('getGspServices') 
  private actionGetGspServices!: any;
  @gspServiceModule.Getter('getGspServices') 
  private gettterGspServices!: any;

  @gspUserRoleModule.Action('updateGspUserRole')
  private actionUpdateGspUserRole!: any;
  @gspUserRoleModule.Getter('getGspUserRoles')
  private userRoles!: any;

  @gspRoleModule.Getter('getGspRoles')
  private gspRoles!: any;

  @Prop()
  private contact!: Contact;

  @Prop()
  private userRole!: any;

  private async getGspServices() {
    if(this.gettterGspServices?.items?.length > 0) {
      console.log('getGspServices gettterGspServices :>> ', this.gettterGspServices);
      this.servicesArr = this.gettterGspServices.items;
    } else {
      await this.actionGetGspServices()
        .then((result: any) => {
          console.log('getGspServices result :>> ', result);
          this.servicesArr = result.value;
        })
        .catch((err: any) => {
          logger.error(err);
        });

    }
  }
  servicesArr: any = [];
  // modulesArr: any = [];
  // attributesArr: any = [];

  get services() {
    return this.servicesArr?.items;
  }

  moduleRoles: any = [];

  // get hasSelectedData() {
  //   const roleData = this.userRole.

  // }
  hasSelectedData(item: any){
    const isRoleHasDetails = this.userRole?.gspUserRoleDetails;
    const hasDetailsForThisService = this.userRole?.gspUserRoleDetails?.find((x: any)=> x.serviceId === item.id);

    if (!isRoleHasDetails || !hasDetailsForThisService) return false;

    // !!TODO (AD-87) optimize this part by introducing calculated field in `GspUserRoleDetail`
    let result:any = {selected: false };
    if (hasDetailsForThisService.roleDetails) {
      let roleDetailData = JSON.parse(hasDetailsForThisService.roleDetails);

      // AD-90 if `roleDetailData` is NULL do nothing and show stubs in accordions (contact-panels)
      if (roleDetailData.length === 0) {
        return result;
      }

      let moduleRoles = roleDetailData[0].moduleRoles.filter((x:any) => x.roles.length > 0);

      // console.log('roleDetailData :>> ', roleDetailData);
      // console.log('roleDetailData[0] :>> ', roleDetailData[0]);
      if(item.zrType == 1) {
        let zrNums = roleDetailData.length;
        // console.log('zrNums :>> ', zrNums);
        let selectedZrNums = roleDetailData.filter((x:any) => x.moduleRoles.filter((y:any) => y.roles.length > 0).length > 0);
        // console.log('selectedZrNums :>> ', selectedZrNums);
        result.text = `(${selectedZrNums.length} ZrNummer von ${zrNums})`;
      } else {
        let allModules = roleDetailData[0].moduleRoles.length;
        // console.log('roleDetailData[0].moduleRoles.length :>> ', roleDetailData[0].moduleRoles.length);
        // console.log('moduleRoles.length :>> ', moduleRoles.length);
        result.text = `(${moduleRoles.length} Modul von ${allModules})`;
      }


      result.selected = moduleRoles.length > 0 ? true : false;


    }
    console.log('result hasSelectedData :>> ', result );
    return result;
  }


  async mounted() {
    var promiseAll = [this.getGspServices()];
    await Promise.all(promiseAll);
    // const result = this.userRoles.items.find((role:any) => role.contactEmail == this.contact.email);
    // console.log('mounted userRole result :>> ', result);
    // console.log('mounted  contact:>> ', this.contact.email);


    if (this.userRole?.zrNumRolesData) {
      this.userRoleData = JSON.parse(this.userRole?.zrNumRolesData);

      this.userRoleData.forEach((element: any, index) => {
        if (element?.zrNummer) {
          Vue.set(this.boxes, index, true);
          Vue.set(this.roleBoxes, index, element.roles);
        }
      });
      this.userRolesPayload = this.userRoleData;
    }
  }

  //#region
  isSaving = false;
  userRolesPayload: any = [];

  boxes: Array<boolean> = [false];
  roleBoxes: Array<any> = [];
  userRoleData = [];

  get zrNumberArr() {
    return this.contact.companyZrNumber?.split(',') || [];
  }

  // get userRole() {
  //   const result = this.userRoles.items.find((role: any) => role.contactEmail == this.contact.email);
  //   return result;
  // }

  get roles() {
    return this.gspRoles.items;
  }
  not(e: any) {
    e.preventDefault();
    e.stopPropagation();

    console.log('e :>> ', e);
  }

  changeZrNumbBox(ev: any, item: any, i: any) {
    console.log('ev :>> ', ev);
    console.log('item :>> ', item);
    console.log('i :>> ', i);
    console.log('this.gspRoles :>> ', this.gspRoles);
    if (!ev) {
      this.removeZrNumRole('zrNum', i);
    }
  }

  removeZrNumRole(source: any, index: number) {
    this.userRolesPayload[index] = null;
    if (source == 'zrNum') {
      Vue.set(this.roleBoxes, index, null);
    }
  }

  changeRole(ev: any, item: any, i: any) {
    if (ev.length == 0) {
      this.removeZrNumRole('role', i);
    }
    console.log('ev :>> ', ev);
    console.log('item :>> ', item);
    console.log('i zr :>> ', i);

    let obj = { zrNummer: item.trim(), roles: ev };
    this.userRolesPayload[i] = obj;
    // this.boxes[i] = ev.length>0 ;
    Vue.set(this.boxes, i, ev.length > 0);

    // Array.prototype.splice
    // vm.items.splice(indexOfItem, 1, newValue)
  }

  save() {
    console.log('save roles:>> ');

    const payload = {
      id: this.userRole?.id || undefined,
      contactId: this.contact.recordID,
      contactEmail: this.contact.email,

      zrNumRolesData: JSON.stringify(this.userRolesPayload),
    };
    console.log('payload :>> ', payload);

    this.actionUpdateGspUserRole(payload);
  }
  //#endregion

}
