import { ActionTree } from 'vuex';
import { AuthState } from './types';
import { RootState } from '../../types';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';
import DownloadUtils from '@/shared/utils/DownloadUtils';
import { Credentials } from '@/shared/model/smallPayloadModels/credentials';
import { defaultBackendAccount } from '@/shared/backend/account';
// import { msalInstance } from 'vue-msal-browser';
import msalPlugin, { msalInstance } from 'vue-msal-browser';

const logger = new Logger('actions.auth');
export const actions: ActionTree<AuthState, RootState> = {
  updateAzureData({ commit }, payload: any) {
    commit('setAzureData', payload);
  },

  async login({ commit, dispatch }, payload: Credentials) {
    // authenticate to get the access token
    return defaultBackendAccount
      .authenticate(payload.username!, payload.password!, payload.rememberMe)
      .then((response: any) => {
        commit('setToken', response.data);
        commit('setAccountId', response.data.userId);
        return response;
      })
      .catch((e: any) => {
        logger.error(e);
        throw e;
      });
  },
  async loadAccountDetails({ commit }, id?: number) {
    return defaultBackendAccount
      .getMyAccount()
      .then((account) => {
        commit('setAccount', account.data.account);
      })
      .catch((e) => {
        commit('setAccount', undefined);
        module_utils.error('', commit, e);
      });
  },

  async loadAzureUserId({ commit }, email: string) {
    return defaultBackendAccount
      .loadAzureUserId(email)
      .then((response) => {
        const id = response.data?.value[0]?.id;
        return id;
      })
      .catch((e: any) => {
        logger.error(e);
        throw e;
      });
  },
  async canSeeAll({ commit }, email: string) {
    return defaultBackendAccount
      .canSeeAllCompanyContacts(email)
      .then((response) => {
        const isInAdminGroup = response.data?.value?.length > 0;
        logger.log('isInAdminGroup :>> ', response.data);
        commit('setIsUserInAzureAdminGroup', isInAdminGroup);
        commit('setUserAdminGroups', response.data?.value);
        return response.data;
      })
      .catch((e: any) => {
        logger.error(e);
        throw e;
      });
  },
  async deactivateUserOnAzure({ commit }, azureUserId: string) {
    return defaultBackendAccount
      .deactivateUserOnAzure(azureUserId)
      .then((response) => {
        return response;
      })
      .catch((e: any) => {
        logger.error(e);
        throw e;
      });
  },
  async activateUserOnAzure({ commit }, azureUserId: string) {
    return defaultBackendAccount
      .activateUserOnAzure(azureUserId)
      .then((response) => {
        return response;
      })
      .catch((e: any) => {
        logger.error(e);
        throw e;
      });
  },

  async getVmeSupplierData({ commit }, supplierId: string) {
    console.log('getVmeSupplierData supplierId:>> ', supplierId);
    return defaultBackendAccount
      .getVmeSupplierData(supplierId)
      .then((response) => {
        return response;
      })
      .catch((e: any) => {
        logger.error(e);
        throw e;
      });
  },
  async getVmeSupplierUmsatz({ commit }, data: any) {
    console.log('getVmeSupplierUmsatz :>> ', data);
    return defaultBackendAccount
      .getVmeSupplierUmsatz(data)
      .then((response) => {
        return response;
      })
      .catch((e: any) => {
        logger.error(e);
        throw e;
      });
  },

  async getVmeApiUserId({ commit }, email: string) {
    console.log('getVmeApiUserId supplierId:>> ', email);
    return defaultBackendAccount
      .getVmeApiUserId(email)
      .then((response) => {
        return response;
      })
      .catch((e: any) => {
        logger.error(e);
        throw e;
      });
  },

  /**
   * Clears the userType effectively logging out the user.
   */
  logout({ commit }) {
    return defaultBackendAccount.logout().then((result) => {
      commit('setAccount', undefined);
      commit('clearTokens');
      return result;
    });
  },
};
