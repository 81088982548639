
export interface GspModule {
    id: number; // int
    name: string; // string
    serviceId: number | undefined; // int
    service: any; // adminPanel.DataAccessPostgreSQL.Database.DataModel.GspServiceDm;
    attributes: any; //System.Collections.Generic.ICollection<adminPanel.DataAccessPostgreSQL.Database.DataModel.GspAttributeDm>;
    created: string; // System.DateTime?
    creatorId: string; // string
    creatorEmail: string; // string

    isCommon: boolean; // boolean
    entra_Group: string; // string
}

export interface Value {
  value: GspModule[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<GspModule>): any {
  return {
    Id: data?.id || 0,
    Name: data?.name || '',
    ServiceId: data?.serviceId || 0,
    Service: data?.service || undefined,
    Attributes: data?.attributes || undefined,
    Created: data?.created || undefined,
    CreatorId: data?.creatorId || undefined,
    CreatorEmail: data?.creatorEmail || undefined,
    IsCommon: data?.isCommon || false,
    Entra_Group: data?.entra_Group || undefined
  };
}

function parse(data?: Partial<GspModule>): GspModule {
  return {
    id: data?.id || 0,
    name: data?.name || '',
    serviceId: data?.serviceId || undefined,
    service: data?.service || '',
    attributes: data?.attributes || '',
    created: data?.created || '',
    creatorId: data?.creatorId || '',
    creatorEmail: data?.creatorEmail || '',
    isCommon: data?.isCommon || false,
    entra_Group: data?.entra_Group || '',
  };
}

export default {
  parse,
  toAPI: toAPI,
};
