import { Contact as ContactModel } from '@/shared/model/contact';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Logger } from 'fsts';
import { namespace } from 'vuex-class';
import Contact from './contact/contact.vue';
import { SearchParams } from '@/shared/model/searchParams';
import DeactivateDialog from './deactivate-dialog/deactivate-dialog.vue';
import FilterDialog from './filter-dialog/filter-dialog.vue';
import DocumentsConditionsDialog from './documents-conditions-dialog/documents-conditions-dialog.vue';
import { SearchData } from '@/shared/model/smallPayloadModels/searchData';

const logger = new Logger('contact-list');
const contactModule = namespace('contact');
const company = namespace('company');
const authModule = namespace('auth');
const gspRoleModule = namespace('gspRole');
const gspServiceModule = namespace('gspService');
const gspUserRoleModule = namespace('gspUserRole');


@Component({
  components: {
    Contact,
    DeactivateDialog,
    FilterDialog,
    DocumentsConditionsDialog,
  },
})
export default class ContactList extends Vue {
  @authModule.Action('canSeeAll')
  private actionCanSeeAll!: any;
  @authModule.Getter('isUserInAzureAdminGroup')
  private isUserInAzureAdminGroup!: any;
  @authModule.Getter('isUserSuperAdmin')
  private isUserSuperAdmin!: any;

  @contactModule.Action('getContacts')
  private actionGetContacts!: any;
  @contactModule.Action('getCompanyZr')
  private actionGetCompanyZrNums!: any;
  @contactModule.Action('getContactsExcelReport')
  private actionGetContactsExcelReport!: any;
  @company.Action('getCompanys')
  private actionGetCompanys!: any;
  @contactModule.Getter('getContacts')
  private getContacts!: any;
  @contactModule.Getter('getContactsIsLoading')
  private contactsIsLoading!: boolean;
  @contactModule.Getter('getContactsSearchParams')
  private contactsSearchParams!: SearchParams;

  @gspRoleModule.Action('getGspRoles')
  private actionGetGspRoles!: any;

  @gspServiceModule.Action('getGspServices') 
  private actionGetGspServices!: any;
  @gspServiceModule.Getter('getGspServices') 
  private gettterGspServices!: any;


  // TODO(GSP-108): load every distinct user role on open the contact later
  @gspUserRoleModule.Action('getGspUserRoles')
  private actionGetGspUserRoles!: any;

  @contactModule.Getter('getSearchData')
  private searchData!: any;

  @Watch('searchData', { deep: true })
  @Watch('contactsSearchParams.filter', { deep: true })
  public async onOptionsFilterChanged(newVal: SearchData, oldVal: SearchData) {
    logger.debug(`----filter:${oldVal}->${newVal}`);
    this.contactsSearchParams.dataOption.page = 1;
    const payload = {
      searchParams: this.contactsSearchParams,
      searchData: {
        isActive: newVal.isActive || this.searchData.isActive, // (AD-92) when filter by searchTerm in `text-field` take default value from Getter (then newVal is text value from `searchTerm` )
        companyZr: newVal.companyZr || this.searchData.companyZr, //this.companyZrNumbers || [],
        isUserInAzureAdminGroup: this.isUserInAzureAdminGroup,
      },
    };

    await this.actionGetContacts(payload).catch((err: any) => {
      logger.error(err);
    });
  }

  private pagination: {
    page: number;
    itemsPerPage: number;
    pageStart: number;
    pageStop: number;
    pageCount: number;
    itemsLength: number;
  } = { page: 1, itemsPerPage: 50, pageStart: 1, pageStop: 0, pageCount: 0, itemsLength: 0 };
  // pageStart is start of page on the X page, pageStop is the end of page on the X page like `151-200 von 4720` for the 4th page with the `itemsPerPage: 50` in the footer text

  private async contactsUpdateOptions(newVal: any, oldVal: any) {
    console.log('newVal contactsUpdateOptions :>> ', newVal);
    console.log('oldVal :>> ', oldVal);
    this.contactsSearchParams.dataOption.page = newVal.page;
    this.contactsSearchParams.dataOption.itemsPerPage = newVal.itemsPerPage;
    // this.employeesSearchParams.filter = newVal.filter;
    if (newVal != oldVal && this.contactsIsLoading === false && this.isUserInAzureAdminGroup) this.getContactsSearch();
  }

  private searchTerm = '';
  private companyZrNumbers = '';
  searchCompanyZr = '';
  сompanyZrs = [];

  private resetSearchCompanyZr(): void {
    this.searchCompanyZr = '';
  }

  clearSearchInput(): void {
    this.searchData.companyZr = [];
    this.resetSearchCompanyZr();
  }
  private onBlurSearchCompanyZr(): void {
    this.resetSearchCompanyZr();
  }

  async created() {
    await this.actionCanSeeAll().catch((err: any) => {
      logger.error(err);
    });
    if (this.isUserInAzureAdminGroup) {
      await this.actionGetGspRoles();
      await this.actionGetGspUserRoles();
      await this.getContactsData();
      await this.getCompanyZrsFromBackend();
      await this.actionGetGspServices()
    }
  }

  async getCompanyZrsFromBackend() {
    await this.actionGetCompanyZrNums()
      .then((result: any) => {
        logger.log('result :>> ', result);
        this.сompanyZrs = result;
      })
      .catch((err: any) => {
        logger.error(err);
      });
  }

  private async getContactsData() {
    // don't take getter value in this case
    await this.actionGetContacts()
      .then((result: any) => {
        logger.log('result :>> ', result);
      })
      .catch((err: any) => {
        logger.error(err);
      });
  }

  private changeSearch(searchTerm: string) {
    if (searchTerm.length < 2) return;
    this.searchContacts(searchTerm);
  }
  private changeSearchInput(value: string) {
    if (value.length == 0) {
      this.resetSearch();
    }
  }
  private resetSearch() {
    const emptyString = '';
    this.searchContacts(emptyString);
  }

  private async searchContacts(filterValue: string) {
    this.contactsSearchParams.filter = filterValue; // `this.getContactsSearch` will be called via `@Watch`
    // if (!this.contactsIsLoading) this.getContactsSearch();
  }
  private async getContactsSearch() {
    await this.actionGetContacts({ searchParams: this.contactsSearchParams, searchData: this.searchData });
  }

  //#region Sorting and Expand/collapse buttons logic
  private isExpandAll = false;

  get IsSortAscending(): boolean {
    return this.contactsSearchParams.dataOption.sortDesc === false;
  }

  private sortContacts() {
    this.contactsSearchParams.dataOption.sortDesc = !this.contactsSearchParams.dataOption.sortDesc;
    this.getContactsSearch();
  }

  private expandCollapseAllContacts() {
    let expansions = document.querySelectorAll<HTMLElement>(
      '.v-expansion-panel-header:not(.v-expansion-panel-header--active)'
    );
    if (this.isExpandAll) {
      expansions = document.querySelectorAll<HTMLElement>('.v-expansion-panel-header.v-expansion-panel-header--active');
    }

    expansions.forEach((element) => {
      if (element instanceof HTMLElement) {
        element.click();
      }
    });
    this.isExpandAll = !this.isExpandAll;
  }
  //#endregion

  deactivateUser(contact: ContactModel): void {
    logger.log('(this.isUserSupportOrSuperAdmin :>> ', this.isUserSuperAdmin);
    if (contact.isActive || this.isUserSuperAdmin) {
      this.dialogDeactivate.model = contact;
      this.showDeactivateDialog();
    }
  }

  //#region Deactivate-dialog
  dialogDeactivate = {
    show: false,
    model: {},
    OnClose: (): void => {
      this.dialogDeactivate.show = false;
    },
  };

  showDeactivateDialog(): void {
    logger.log('show-deactivate-dialog');
    this.dialogDeactivate.show = true;
  }
  //#endregion

  //#region Filter-dialog
  dialogFilter = {
    show: false,
    model: {},
    OnClose: () => {
      this.dialogFilter.show = false;
    },
  };

  showFilterDialog() {
    logger.log('show-filter-dialog');
    this.dialogFilter.show = true;
  }

  showFilter() {
    this.dialogFilter.show = true;
  }
  //#endregion

  isLoadingFile = false;
  downloadExcel() {
    const payload = {
      searchParams: this.contactsSearchParams,
      ...this.searchData,
    };
    this.isLoadingFile = true;
    this.actionGetContactsExcelReport(payload).finally(() => {
      this.isLoadingFile = false;
    });
  }

  //#region Documents-conditions-dialog
  dialogDocumentConditions: any = {
    show: false,
    model: {},
    OnClose: () => {
      this.dialogDocumentConditions.show = false;
    },
    UpdateContactPanelId: (id: any) => {
      this.updateModel(id);
    },
  };

  updateModel(id: any) {
    logger.log('panel id :>> ', id);
    this.dialogDocumentConditions.model.contactPanelId = id;
  }

  showDocumentConditionsDialog(contact: ContactModel): void {
    logger.log('show-filter-dialog');
    this.dialogDocumentConditions.model = contact;
    this.dialogDocumentConditions.show = true;
  }

  //#endregion
}
