import { Component, Vue } from 'vue-property-decorator';
import DialogBoxComponent from '@/components/dialogBox/dialogBox';
import { Action, Getter, namespace, State } from 'vuex-class';
import { Logger } from 'fsts';
import { ROUTES } from '@/router/routesEnum';
import { msalInstance } from 'vue-msal-browser';

const logger = new Logger('app');
const authModule = namespace('auth');
const contactModule = namespace('contact');
const companyModule = namespace('company');

@Component({
  components: {},
})
export default class App extends Vue {
  public $refs!: Vue['$refs'] & {
    dialogBox: DialogBoxComponent;
  };
  @State('snackbar')
  private snackbar!: any; //Snackbar
  @Action('hideSnackbar') private releaseSnackbar: any;

  @authModule.Getter('isLoggedIn')
  private isLoggedIn!: boolean;
  @authModule.Getter('getUser')
  private user!: any;
  @authModule.Getter('azureUser')
  private azureUser!: any;
  @authModule.Getter('isUserSupportOrSuperAdmin')
  private isUserSupportOrSuperAdmin!: any;
  @authModule.Action('logout')
  private logoutAction: any;
  @authModule.Action('updateAzureData')
  private updateAzureData: any;
  @contactModule.Action('getContactByEmail')
  private getLoggedInContactByEmail: any;

  @companyModule.Action('getUserCompanyZr')
  private actionGetUserCompanyZr: any;

  get mobile() {
    return this.$vuetify.breakpoint.mdAndDown;
  }

  drawer = false;
  toggleMini = true;
  maxHeight = 32;
  maxWidth = 35;

  header = 'title';

  leftMenuItemsArr: {
    src: string;
    translateId?: string;
    to?: string;
  }[] = [
    {
      src: 'ico_select',
      translateId: 'select',
      to: '/',
    },
    // {
    //   src: 'ico_select',
    //   translateId: 'roles',
    //   to: '/roles',
    // },
    // {
    //   src: 'ico_select',
    //   translateId: 'select',
    //   to: '/vmesupplier',
    // },
  ];

  get leftMenuItems(){
    // AD-84 from 2024-02-084 allow to see `Roles` only in local and test Area (filtered by domain) (this limitation should be deleted later)
    if (window.location.host.includes('dev4you') || window.location.host.includes('localhost')) {

      this.leftMenuItemsArr[1] =    {
        src: 'ico_select',
        translateId: 'roles',
        to: '/roles',
      };

    }

    return this.leftMenuItemsArr;
  }

  created() {
    logger.log('Hello from github Actions:>> 0.2.11 WITHOUT VOLUMES ');
    logger.log('process.env :>> ', process.env);
    this.$msal
      .authenticateRedirect()
      .then((result: any) => {
        // (AD-80) when use `vme-supplier` component in <iframe> tag in Sharepoint portal there is NO Azure data since redirect is NOT allowed (to azure login page)
        logger.log('Azure logging :>> ', result);
        this.updateAzureData(result).then(() => {
          this.getLoggedInContactByEmail().then((result: any) => {
            console.log('getLoggedInContactByEmail result :>> ', result);
            // public string ZRNummer { get; set; }
            // public bool IsUserInAzureAdminGroup { get; set; }
            console.log('result.primary_Company_Zr_Nummer :>> ', result.primary_Company_Zr_Nummer);
            let payload = {
              zrNummer: result.primary_Company_Zr_Nummer + '',
              isUserInAzureAdminGroup: this.isUserSupportOrSuperAdmin,
            };
            console.log('payload :>> ', payload);
            this.actionGetUserCompanyZr(payload);
          });
        });
        this.handleRedirectAfterAzureLogin();
        // this.$router.push({ name: ROUTES.home });
      })
      .catch((err: any) => {
        logger.error(err);
      });
    setTimeout(() => {}, 3300);
  }

  private handleRedirectAfterAzureLogin() {
    // old logic
    // const currentRoute = this.$router.currentRoute;
    // const redirectPath = currentRoute.query.redirect;
    // if (currentRoute.name == ROUTES.login && redirectPath) {
    //   this.$router.push({ path: redirectPath + '' });
    // } else if (currentRoute.name == ROUTES.login) {
    //   this.$router.push({ name: ROUTES.home });
    // }
    let homeNavLink: any = document.querySelector('[href="/"]');
    homeNavLink.click();

    for (let index = 1; index <= 3; index++) {
      setTimeout(() => {
        this.goToInitialBrowserPath(index);
      }, index * 1300);
    }
  }
  goToInitialBrowserPath(index: any) {
    console.log('index goToInitialBrowserPath:>> ', index);
    const initialBrowserPath = localStorage.getItem('initialBrowserPath');
    if (initialBrowserPath) {
      // console.log('this.$route.path :>> ', this.$route.path);
      // console.log('this.$route :>> ', this.$route);
      // console.log('this.$router current :>> ', this.$router.currentRoute.path);
      // console.log('initialBrowserPath :>> ', initialBrowserPath);
      // localStorage.removeItem('initialBrowserPath');
      if (this.$router.currentRoute.path == initialBrowserPath || index == 3) {
        localStorage.removeItem('initialBrowserPath');
        // this.updateInitialRedirect(false);
      } else {
        // added `catch` to avoid next router error: `NavigationDuplicated`
        this.$router.push({ path: initialBrowserPath }).catch(() => {});
      }
    }
  }

  mounted() {
    // placing a global component in app.vue.html and adding ref options (e.g. <myComponent ref="mycomp"></myComponent>)
    // then make a ref global:
    this.$confirm.open = this.$refs.dialogBox.openConfirm;
    this.$message.open = this.$refs.dialogBox.openMessageBox;

    const locale = localStorage.getItem('locale') || this.$i18n.locale; // default locale is `DE` see `i18n.ts`
    this.setLocale(locale);
  }

  private setLocale(locale: string) {
    localStorage.setItem('locale', locale);
    this.$root.$i18n.locale = locale;
    this.$vuetify.lang.current = locale;
  }

  private setHeader(value: string) {
    if (value == 'select') {
      value = 'title';
    }
    this.header = value;
  }

  get hasAzureUser() {
    return this.azureUser?.name;
  }

  async logout() {
    // await this.logoutAction();
    await this.$msal.logoutRedirect();

    this.$router.push({ name: ROUTES.login });
  }
}
