/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Prop, Vue } from 'vue-property-decorator';

import { Logger } from 'fsts';
import { namespace } from 'vuex-class';
import { Contact } from '@/shared/model/contact';
import gsp, { GspUserRoleDetail } from '@/shared/model/gspUserRoleDetail';
import { JsonTypes } from '@azure/msal-common/dist/utils/Constants';

const logger = new Logger('module-role-table');
const contactModule = namespace('contact');
const contactPanelModule = namespace('contactPanel');
const gspRoleModule = namespace('gspRole');
const gspUserRoleModule = namespace('gspUserRole');
const gspServiceModule = namespace('gspService');
const gspUserRoleDetailModule = namespace('gspUserRoleDetail');

@Component({
  components: {},
})
export default class ModuleRoleTable extends Vue {
  @gspServiceModule.Action('getGspServices')
  private actionGetGspServices!: any;
  @gspUserRoleModule.Action('addRoleFromBackend')
  private actionAddRoleFromBackend!: any;
  @gspUserRoleModule.Action('addRoleDetailFromBackend')
  private actionAddRoleDetailFromBackend!: any;
  @gspUserRoleDetailModule.Action('getGspUserRoleDetail')
  private actionGetGspUserRoleDetail!: any;
  @gspUserRoleDetailModule.Action('updateGspUserRoleDetail')
  private actionUpdateGspUserRoleDetail!: any;

  @gspUserRoleModule.Action('updateGspUserRole')
  private actionUpdateGspUserRole!: any;
  @gspUserRoleModule.Getter('getGspUserRoles')
  private userRoles!: any;

  @gspRoleModule.Getter('getGspRoles')
  private gspRoles!: any;

  @Prop()
  private serviceModules!: any;
  @Prop()
  private zrNumberArr!: any;

  @Prop()
  private userRole!: any;
  @Prop()
  private serviceId!: any;
  @Prop()
  private contactId!: any;
  @Prop()
  private contactEmail!: any;
  @Prop()
  private isSingleRow!: boolean;

  moduleZrNumArr: any = [];
  moduleZrNumArrBackup: any = [];

  private async getGspServices() {
    await this.actionGetGspServices()
      .then((result: any) => {
        this.servicesArr = result.value;
      })
      .catch((err: any) => {
        logger.error(err);
      });
  }
  servicesArr: any = [];
  // modulesArr: any = [];
  // attributesArr: any = [];

  get isHaveCommonModules() {
    return this.serviceModules?.filter((x: any) => x.isCommon).length > 1;
  }

  get roleServices() {
    return this.roles?.map((x: any) => x.id);
    // return this.userRole?.gspUserRoleDetails.map((x:any) => x.serviceId);
  }

  // get services() {
  //   return this.servicesArr?.items;
  // }

  /**
   *
   * var lat = [
  [45.4668729, 44.8013268, 45.4384958]
];
var lng = [
  [9.1907501, 10.3278351, 10.9924122]
];

var finalArray = []

lat[0].forEach((i, index)=>{
  finalArray.push([i,lng[0][index]])
})
   */
  currentRoleDetail?: GspUserRoleDetail = gsp.parse({});

  async mounted() {
    // var promiseAll = [this.getGspServices()];
    // await Promise.all(promiseAll);
    // // const result = this.userRoles.items.find((role:any) => role.contactEmail == this.contact.email);
    // // console.log('mounted userRole result :>> ', result);
    // // console.log('mounted  contact:>> ', this.contact.email);

    this.currentRoleDetail = this.userRole?.gspUserRoleDetails.find((x: any) => x.serviceId === this.serviceId);

    // this.serviceModules.forEach((el:any, index: number) => {
    //   this.moduleZrNumArr.push({'zrNummer': this.zrNumberArr[index],'module': el})
    // })
    console.log('this.userRole :>> ', this.userRole);
    let modules = this.serviceModules;
    // console.log('this.serviceModules :>> ', this.serviceModules);

    if (this.isHaveCommonModules) {

      if (this.serviceModules.length > 1 && this.serviceModules[0].name.includes(this.serviceModules[1].name)) {
        console.log('already all common modules concatenated :>> ');
        // (AD-95) fix bug that `common` long modules string is duplicated for each new Contact (without created role)
        modules = [this.serviceModules[0]];
      } else {
        const result = this.serviceModules.reduce((acc: any, cur: any) => {
          let doesExist = acc.find((a: any) => a.isCommon);
          if (doesExist && cur.isCommon) {
            doesExist.name = `${doesExist.name},${cur.name}`;
            doesExist.isCommon = true;
          } else {
            acc.push(cur);
          }
          return acc;
        }, []);
        modules = result;
      }

    
    }

    const roleDetails =
      this.currentRoleDetail && this.currentRoleDetail.roleDetails && this.currentRoleDetail.roleDetails.length > 4; // (AD-90) length should be more than empty array or object

    if (this.userRole?.id && this.userRole.gspUserRoleDetails.length > 0 && roleDetails) {
      // !! TODO(AD-89) decide how to read existing data if Modules `IsCommon` checkbox was changed
      this.moduleZrNumArr = JSON.parse(this.currentRoleDetail!.roleDetails);
    } else {
      this.initNewData(modules);
    }

    // if (this.userRole?.zrNumRolesData) {
    //   this.userRoleData = JSON.parse(this.userRole?.zrNumRolesData);

    //   this.userRoleData.forEach((element: any, index) => {
    //     if (element?.zrNummer) {
    //       Vue.set(this.boxes, index, true);
    //       Vue.set(this.roleBoxes, index, element.roles);
    //     }
    //   });
    //   this.userRolesPayload = this.userRoleData;
    // }
  }

  initNewData(modules: any) {
    if (this.isSingleRow) {
      const moduleRoles = modules.map((x: any) => {
        return { id: x.id, name: x.name, roles: [] };
      });
      const zrNummerAll = this.zrNumberArr.map((x: any) => x.trim()).join(',');

      this.moduleZrNumArr.push({ zrNummerAll: zrNummerAll, moduleRoles: moduleRoles });
    } else {
      this.zrNumberArr.forEach((el: any, index: number) => {
        const moduleRoles = modules.map((x: any) => {
          return { id: x.id, name: x.name, roles: [] };
        });

        this.moduleZrNumArr.push({ zrNummer: el.toString().trim(), moduleRoles: moduleRoles });
      });
    }
  }

  changeModuleRole(ev: any, item: any, modPosIndex: any, zr: any) {
    console.log('ev :>> ', ev);
    console.log('item :>> ', item);
    console.log('i zr :>> ', modPosIndex);
    console.log('zrzr zr :>> ', zr);

    // let zrss = this.moduleZrNumArr.find((x:any)=> x.zrNummer == zr)g
    // console.log('zrss :>> ', zrss);
    // zrss.moduleRoles[modPosIndex].roles= ev;
    let roleDetailsModel = gsp.parse({});
    if (this.currentRoleDetail?.id) {
      console.log('this.currentRoleDetail?.id :>> ', this.currentRoleDetail?.id);
      roleDetailsModel = gsp.parse(this.currentRoleDetail);
    }
    if (!this.userRole?.id) {
      roleDetailsModel.contactId = this.contactId;
      roleDetailsModel.contactEmail = this.contactEmail;
    }

    roleDetailsModel.roleDetails = JSON.stringify(this.moduleZrNumArr);
    roleDetailsModel.gspUserRoleId = this.userRole?.id || this.currentRoleDetail?.gspUserRoleId;
    roleDetailsModel.serviceId = this.serviceId;

    this.actionUpdateGspUserRoleDetail(roleDetailsModel)
      .then((result: any) => {
        console.log('changeModuleRole actionUpdateGspUserRoleDetail result :>> ', result);
        this.currentRoleDetail = gsp.parse(result.result);
        this.currentRoleDetail.gspUserRole = null; // avoid  error when select multiple Roles for nonexisting `UserRole` (or need `parse` `UserRole` model)

        if (!this.userRole?.id) {
          console.log('result.result.gspUserRole :>> ', result.result.gspUserRole);
          let payload = result.result.gspUserRole;
          payload.gspUserRoleDetails = [this.currentRoleDetail];
          this.actionAddRoleFromBackend(payload);
        } else {
          this.actionAddRoleDetailFromBackend(result.result);
        }
      })
      .catch((err: any) => {
        logger.error(err);
      });
  }

  // changeRole(ev: any, item: any, i: any) {
  //   if (ev.length == 0) {
  //     this.removeZrNumRole('role', i);
  //   }
  //   console.log('ev :>> ', ev);
  //   console.log('item :>> ', item);
  //   console.log('i zr :>> ', i);

  //   let obj = { zrNummer: item.trim(), roles: ev };
  //   this.userRolesPayload[i] = obj;
  //   // this.boxes[i] = ev.length>0 ;
  //   Vue.set(this.boxes, i, ev.length > 0);

  //   // Array.prototype.splice
  //   // vm.items.splice(indexOfItem, 1, newValue)
  // }

  //#region
  isSaving = false;
  userRolesPayload: any = [];

  boxes: Array<boolean> = [false];
  roleBoxes: Array<any> = [];
  userRoleData = [];

  // get userRole() {
  //   // const result = this.userRoles.items.find((role: any) => role.contactEmail == this.contact.email);
  //   const result: any = '';
  //   return result;
  // }

  get roles() {
    var serviceRoles = this.gspRoles.items.filter((r: any) => r.rolesDataServiceIds.includes(this.serviceId));
    return serviceRoles;
    return this.gspRoles.items;
  }
  not(e: any) {
    e.preventDefault();
    e.stopPropagation();

    console.log('e :>> ', e);
  }

  changeZrNumbBox(ev: any, item: any, i: any) {
    console.log('ev :>> ', ev);
    console.log('item :>> ', item);
    console.log('i :>> ', i);
    console.log('this.gspRoles :>> ', this.gspRoles);
    if (!ev) {
      this.removeZrNumRole('zrNum', i);
    }
  }

  removeZrNumRole(source: any, index: number) {
    this.userRolesPayload[index] = null;
    if (source == 'zrNum') {
      Vue.set(this.roleBoxes, index, null);
    }
  }

  changeRole(ev: any, item: any, i: any) {
    if (ev.length == 0) {
      this.removeZrNumRole('role', i);
    }
    console.log('ev :>> ', ev);
    console.log('item :>> ', item);
    console.log('i zr :>> ', i);

    let obj = { zrNummer: item.trim(), roles: ev };
    this.userRolesPayload[i] = obj;
    // this.boxes[i] = ev.length>0 ;
    Vue.set(this.boxes, i, ev.length > 0);

    // Array.prototype.splice
    // vm.items.splice(indexOfItem, 1, newValue)
  }

  save() {
    console.log('save roles:>> ');

    // const payload = {
    //   id: this.userRole?.id || undefined,
    //   contactId: this.contact.recordID,
    //   contactEmail: this.contact.email,
    //   zrNumRolesData: JSON.stringify(this.userRolesPayload),
    // };
    // console.log('payload :>> ', payload);

    // this.actionUpdateGspUserRole(payload);
  }
  //#endregion
}
