
export interface GspService {
    id: number; // int
    name: string; // string
    // roles: any // System.Collections.Generic.ICollection<adminPanel.DataAccessPostgreSQL.Database.DataModel.GspRole>;
    modules: any // System.Collections.Generic.ICollection<adminPanel.DataAccessPostgreSQL.Database.DataModel.GspModuleDm>;
    created: string; // System.DateTime?
    creatorId: string; // string
    creatorEmail: string; // string
    zrType: number; // byte
    entra_Group: string; // string

}

export interface Value {
  value: GspService[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<GspService>): any {
  return {
    Id: data?.id || 0,
    Name: data?.name || '',
    // Roles: data?.roles || [],
    Modules: data?.modules || undefined,
    Created: data?.created || undefined,
    CreatorId: data?.creatorId || undefined,
    CreatorEmail: data?.creatorEmail || undefined,
    ZrType: data?.zrType || 0,
    Entra_Group: data?.entra_Group || undefined
  };
}

function parse(data?: Partial<GspService>): GspService {
  return {
    id: data?.id || 0,
    name: data?.name || '',
    // roles: data?.roles || '',
    modules: data?.modules || '',
    created: data?.created || '',
    creatorId: data?.creatorId || '',
    creatorEmail: data?.creatorEmail || '',
    zrType: data?.zrType || 0,
    entra_Group: data?.entra_Group || '',
  };
}

export default {
  parse,
  toAPI: toAPI,
};
