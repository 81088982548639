
export interface GspUserRoleDetail {
    id: number; // uint
    gspUserRoleId: number; // uint
    gspUserRole: any;// adminPanel.DataAccessPostgreSQL.Database.DataModel.GspUserRoleDm;
    roleDetails: string; // string
    created: string; // System.DateTime?
    creatorId: string; // string
    creatorEmail: string; // string
    serviceId: number;

    contactId: number; //
    contactEmail: string;

}

export interface Value {
  value: GspUserRoleDetail[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<GspUserRoleDetail>): any {
  return {
    Id: data?.id || 0,
    GspUserRoleId: data?.gspUserRoleId || 0,
    GspUserRole: data?.gspUserRole || undefined,
    RoleDetails: data?.roleDetails || '',
    Created: data?.created || undefined,
    CreatorId: data?.creatorId || undefined,
    CreatorEmail: data?.creatorEmail || undefined,
    ServiceId: data?.serviceId || undefined,
    ContactId: data?.contactId || 0,
    ContactEmail: data?.contactEmail || '',
  };
}

function parse(data?: Partial<GspUserRoleDetail>): GspUserRoleDetail {
  return {
    id: data?.id || 0,
    gspUserRoleId: data?.gspUserRoleId || 0,
    gspUserRole: data?.gspUserRole || '',
    roleDetails: data?.roleDetails || '',
    created: data?.created || '',
    creatorId: data?.creatorId || '',
    creatorEmail: data?.creatorEmail || '',
    serviceId: data?.serviceId || 0,
    contactId: data?.contactId || 0,
    contactEmail: data?.contactEmail || '',
  };
}

export default {
  parse,
  toAPI: toAPI,
};
