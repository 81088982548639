
export interface GspUserRole {
    id: number; // int
    contactId?: string; // ulong
    contactEmail: string;
    contact: any; // adminPanel.DataAccessPostgreSQL.Database.DataModel.ContactDm

    zrNumRolesData: string;
    //roles: any; // System.Collections.Generic.ICollection<adminPanel.DataAccessPostgreSQL.Database.DataModel.GspRole>
    created: string; // System.DateTime?
    creatorId: string; // string
    creatorEmail: string; // string
    gspUserRoleDetails: any;

}

export interface Value {
  value: GspUserRole[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<GspUserRole>): any {
  return {
    Id: data?.id || 0,
    ContactId: data?.contactId || undefined,
    ContactEmail: data?.contactEmail || '',
    Contact: data?.contact || undefined,
    ZrNumRolesData: data?.zrNumRolesData || '',
    // Roles: data?.roles || '',
    Created: data?.created || '',
    CreatorId: data?.creatorId || '',
    CreatorEmail: data?.creatorEmail || '',
    GspUserRoleDetails: data?.gspUserRoleDetails || undefined,
  };
}

function parse(data?: Partial<GspUserRole>): GspUserRole {
  return {
    id: data?.id || 0,
    contactId: data?.contactId || undefined,
    contactEmail: data?.contactEmail || '',
    contact: data?.contact || '',
    zrNumRolesData: data?.zrNumRolesData || '',
    // roles: data?.roles || '',
    created: data?.created || '',
    creatorId: data?.creatorId || '',
    creatorEmail: data?.creatorEmail || '',
    gspUserRoleDetails: data?.gspUserRoleDetails || '',
  };
}

export default {
  parse,
  toAPI: toAPI,
};
