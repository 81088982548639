import { ActionTree } from 'vuex';
import { ContactState } from './types';
import { RootState } from '../../types';
import { defaultBackendContact } from '@/shared/backend/contact';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import contact, { Value, Contact } from '@/shared/model/contact';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';
import searchData, { SearchData } from '@/shared/model/smallPayloadModels/searchData';
import store from '../../index';
import DownloadUtils from '@/shared/utils/DownloadUtils';
// import { CONST } from '../utils/Constants';

const logger = new Logger('actions.contacts');
export const actions: ActionTree<ContactState, RootState> = {
  getContacts({ commit, dispatch, getters, rootGetters }, payload?: { searchParams?: any; searchData?: any }) {
    commit('setContactsIsLoading', true);
    const isAdmin = store.state.auth.isUserInAzureAdminGroup;
    const isInSuperAdminOrSupportGroup = rootGetters['auth/isUserSupportOrSuperAdmin']; //.isUserSupportOrSuperAdmin;
    const searchParams = payload?.searchParams ?? getters.getContactsSearchParams;
    // let organisationId = rootGetters[CONST.getOrganisation].id;
    const searchDataParam: SearchData = payload?.searchData ?? searchData.defaultData();
    searchDataParam.isUserInAzureAdminGroup = isInSuperAdminOrSupportGroup;
    return defaultBackendContact
      .getContacts(searchParams, searchDataParam)
      .then((response: AxiosResponse<Value>) => {
        commit('setContacts', response.data);
        commit('setContactsIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setContactsIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getContact({ commit, dispatch }, id: string) {
    return defaultBackendContact
      .getContact(id)
      .then((response: AxiosResponse<Contact>) => {
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getContactByEmail({ commit, dispatch }) {
    const loggedInUserEmail = store.state.auth.azureData.username;
    logger.log('getContactByEmail Action >> loggedInUserEmail :>> ', loggedInUserEmail);
    return defaultBackendContact
      .getContactByEmail(loggedInUserEmail)
      .then((response: AxiosResponse<Contact>) => {
        commit('setLoggedInContact', response.data);
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getCompanyZr({ commit, dispatch, rootGetters }, id: string) {
    const isAdmin = store.state.auth.isUserInAzureAdminGroup;
    const isInSuperAdminOrSupportGroup = rootGetters['auth/isUserSupportOrSuperAdmin'];
    return defaultBackendContact
      .getCompanyZr(isInSuperAdminOrSupportGroup)
      .then((response: AxiosResponse<any>) => {
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },


  getContactsExcelReport({ commit, dispatch, getters, rootGetters }, payload?: any) {
    let searchParams = payload?.searchParams ?? getters.getContactsSearchParams;
    return defaultBackendContact
      .getContactsExcelReport(payload, searchParams)
      .then((response: any) => {
        if (response.headers['content-disposition']) {
          const fileName = DownloadUtils.getFileName(response.headers['content-disposition']);
          const type = response.headers['content-type'];
          console.log('type :>> ', type);
          const blob = new Blob([response.data], { type: type });
          console.log('blob :>> ', blob);
          const url = DownloadUtils.createDownloadLink(blob, fileName);
          console.log('url :>> ', url);
          window.URL.revokeObjectURL(url);
        }
        console.log('response.data :>> ', response.data);
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('getContactsExcelReport error', commit, e, logger);
        throw e;
      });
  },

  updateContact({ commit, dispatch }, file: Contact) {
    return defaultBackendContact
      .updateContact(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: Contact;
          }>
        ) => {
          module_utils.ok(i18n.tc(`success.contact_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateContactRights({ commit, dispatch }, payload: any) {
    return defaultBackendContact
      .updateContactRights(payload)
      .then((response: AxiosResponse<any>) => {
        module_utils.ok(i18n.tc(`success.contact_rights_update`), commit);
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  deactivateContact({ commit, dispatch }, payload: any) {
    return defaultBackendContact
      .deactivateContact(payload)
      .then((response: AxiosResponse<any>) => {
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  deleteContact({ commit, dispatch }, id: string) {
    return defaultBackendContact
      .deleteContact(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: Contact;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },

  updateSearchData({ commit, dispatch }, data: any) {
    commit('setSearchData', data);
  },
};
