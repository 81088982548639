
import { ActionTree } from 'vuex';
import { GspUserRoleDetailState } from './types';
import { RootState } from '../../types';
import { defaultBackendGspUserRoleDetail } from '@/shared/backend/gspUserRoleDetail';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import gspUserRoleDetail, { Value, GspUserRoleDetail } from '@/shared/model/gspUserRoleDetail';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';

const logger = new Logger('actions.gspUserRoleDetails');
export const actions: ActionTree<GspUserRoleDetailState, RootState> = {
  getGspUserRoleDetails({ commit, dispatch, getters, rootGetters }, payload?: { searchParams?: any, userRoleId?: string }) {
    commit('setGspUserRoleDetailsIsLoading', true);
    let searchParams = payload?.searchParams ?? getters.getGspUserRoleDetailsSearchParams;
    return defaultBackendGspUserRoleDetail
      .getGspUserRoleDetails(searchParams, payload?.userRoleId)
      .then((response: AxiosResponse<Value>) => {
        commit('setGspUserRoleDetails', response.data);
        commit('setGspUserRoleDetailsIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setGspUserRoleDetailsIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getGspUserRoleDetail({ commit, dispatch }, id: string) {
    return defaultBackendGspUserRoleDetail
      .getGspUserRoleDetail(id)
      .then((response: AxiosResponse<GspUserRoleDetail>) => {
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateGspUserRoleDetail({ commit, dispatch }, file: GspUserRoleDetail) {
    return defaultBackendGspUserRoleDetail
      .updateGspUserRoleDetail(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: GspUserRoleDetail;
          }>
        ) => {
          module_utils.ok(i18n.tc(`success.gspUserRoleDetail_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  deleteGspUserRoleDetail({ commit, dispatch }, id: string) {
    return defaultBackendGspUserRoleDetail
      .deleteGspUserRoleDetail(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: GspUserRoleDetail;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
};
