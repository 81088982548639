
import { GetterTree } from 'vuex';
import { GspUserRoleState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<GspUserRoleState, RootState> = {
  ['getGspUserRoles']: (state) => state.gspUserRoles,
  ['getGspUserRolesIsLoading']: (state) => state.gspUserRoles?.isLoading,
  ['getGspUserRolesTotal']: (state) => state.gspUserRoles?.total,
  ['getGspUserRolesSearchParams']: (state) => state.gspUserRoles?.searchParams,
};
