
import { ActionTree } from 'vuex';
import { GspUserRoleState } from './types';
import { RootState } from '../../types';
import { defaultBackendGspUserRole } from '@/shared/backend/gspUserRole';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import gspUserRole, { Value, GspUserRole } from '@/shared/model/gspUserRole';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';
// import { CONST } from '../utils/Constants';

const logger = new Logger('actions.gspUserRoles');
export const actions: ActionTree<GspUserRoleState, RootState> = {
  getGspUserRoles({ commit, dispatch, getters, rootGetters }, payload?: { searchParams?: any }) {
    commit('setGspUserRolesIsLoading', true);
    let searchParams = payload?.searchParams ?? getters.getGspUserRolesSearchParams;
    // let organisationId = rootGetters[CONST.getOrganisation].id;
    return defaultBackendGspUserRole
      .getGspUserRoles(searchParams)
      .then((response: AxiosResponse<Value>) => {
        commit('setGspUserRoles', response.data);
        commit('setGspUserRolesIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setGspUserRolesIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getGspUserRole({ commit, dispatch }, id: string) {
    return defaultBackendGspUserRole
      .getGspUserRole(id)
      .then((response: AxiosResponse<GspUserRole>) => {
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateGspUserRole({ commit, dispatch }, file: GspUserRole) {
    return defaultBackendGspUserRole
      .updateGspUserRole(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: GspUserRole;
          }>
        ) => {
          module_utils.ok(i18n.tc(`success.gspUserRole_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  deleteGspUserRole({ commit, dispatch }, id: string) {
    return defaultBackendGspUserRole
      .deleteGspUserRole(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: GspUserRole;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },

  addRoleFromBackend({ commit, dispatch }, data: any){
    commit('addRoleFromBackend', data);
  },
  addRoleDetailFromBackend({ commit, dispatch }, data: any){
    console.log('addRoleDetailFromBackend :>> ', data);
    commit('addRoleDetailFromBackend', data);
  }
};
