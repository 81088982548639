
import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import gspService, { GspService, Value } from '../model/gspService';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('backend.GspService');
export interface BackendGspService {
  getGspService(id: string): AxiosPromise<GspService>;
  getGspServices: (searchParams: SearchParams) => AxiosPromise<Value>;
  deleteGspService(id: string): AxiosPromise;
  updateGspService(GspService: GspService): AxiosPromise<any>;
}

export const defaultBackendGspService: BackendGspService = {
  getGspService(id: string): AxiosPromise<GspService> {
    let url = `${URLS.gspService}/${id}`;
    return instance.get<GspService>(url);
  },
  getGspServices(searchParams: SearchParams): AxiosPromise<Value> {
    var odfb = ODataFilterBuilder('and');
    // odfb.eq(CONST.OrganisationId, organisationId, false);
    let url = DefaultBackendHelper.makeUrl(
      `${URLS.gspServiceOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb,
      ['$expand=Modules($select=id,name,isCommon)']
    );
    logger.log(`getGspServices${url}`);
    return instance.get<Value>(url);
  },

  deleteGspService(id: string): AxiosPromise {
    logger.debug('deleteGspService');
    return instance.delete(`${URLS.gspService}/${id}`);
  },
  updateGspService(GspService: GspService): AxiosPromise<any> {
    logger.debug('updateGspService');
    return instance.put<GspService>(`${URLS.gspService}/update`, gspService.toAPI(GspService));
  },
};
