import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import contact, { Contact, Value } from '../model/contact';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('backend.Contact');
export interface BackendContact {
  getContact(id: string): AxiosPromise<Contact>;
  getContactByEmail(email: string): AxiosPromise<Contact>;
  getContacts: (searchParams: SearchParams, searchData?: any) => AxiosPromise<Value>;
  getCompanyZr(isAllCompanies: boolean): AxiosPromise<any>;
  deleteContact(id: string): AxiosPromise;
  updateContact(Contact: Contact): AxiosPromise<any>;
  updateContactRights(Contact: any): AxiosPromise<any>;
  deactivateContact(contactDto: any): AxiosPromise<any>;

  getContactsExcelReport(data: any, searchParams: SearchParams): AxiosPromise<any>;
}

export const defaultBackendContact: BackendContact = {
  getContact(id: string): AxiosPromise<Contact> {
    const url = `${URLS.contactOdata}/${id}`;
    return instance.get<Contact>(url);
  },
  getContactByEmail(id: string): AxiosPromise<Contact> {
    const url = `${URLS.contact}/getContactByEmail/${id}`;
    return instance.get<Contact>(url);
  },
  getCompanyZr(isAllCompanies: boolean): AxiosPromise<any> {
    const url = `${URLS.contact}/GetCompanyZr?isAllCompanies=${isAllCompanies}`;
    return instance.get<Contact>(url);
  },
  getContacts(searchParams: SearchParams, searchData?: any): AxiosPromise<Value> {
    const odfb = ODataFilterBuilder('and');
    const url = DefaultBackendHelper.makeUrl(
      `${URLS.contactOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    logger.log(`getContacts${url}`);
    return instance.put<Value>(url, searchData);
  },

  deleteContact(id: string): AxiosPromise {
    logger.debug('deleteContact');
    return instance.delete(`${URLS.contact}/${id}`);
  },
  updateContact(Contact: Contact): AxiosPromise<any> {
    logger.debug('updateContact');
    return instance.put<Contact>(`${URLS.contact}/update`, contact.toAPI(Contact));
  },
  updateContactRights: function (ContactRights: any): AxiosPromise<any> {
    logger.debug('updateContactRights');
    return instance.put<Contact>(`${URLS.contact}/updateContactRights`, ContactRights);
  },
  deactivateContact: (contactDto: any): AxiosPromise<any> => {
    logger.debug('deactivateContact');
    return instance.put<Contact>(`${URLS.contact}/deactivateContact`, contactDto);
  },

  getContactsExcelReport(data: any, searchParams: SearchParams): AxiosPromise<any> {
    const payload = JSON.parse(JSON.stringify(searchParams)); // deep copy otherwise `searchParams` state changed and extra request to backend happened
    // payload.dataOption.itemsPerPage = 10000;
    let url = DefaultBackendHelper.makeUrl(
      `${URLS.contact}/GetContactsExcelReport`,
      payload.dataOption,
      payload.orClauseFieldsIds,
      payload.filter
    );
    // data = {};
    data.searchWord = payload.filter;
    logger.debug(`GetContactsExcelReport${url}`);
    return instance.put<any>(url, data, {
      responseType: 'blob',
    });
  },
};
