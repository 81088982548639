import { AxiosPromise } from 'axios';
import { instance } from '.';
import { Credentials } from '../model/smallPayloadModels/credentials';
import { URLS } from './index';

// aad-app-admpnl-vmesupport-prod; Group-ID: 496adf28-cb09-4ad9-8f2b-35e4a3428281
// aad-app-admpnl-vmeadmin-prod; Group-ID: 80eee2f3-d108-4cf3-8505-d93d9a698fef
// aad-app-admpnl-gsadmin-prod; Group-ID: 3193d9d0-36c4-4e31-ae6e-c011405301e9
export const vmeSupportGroup = 'aad-app-admpnl-vmesupport-prod';
export const vmeSupportGroupId = '496adf28-cb09-4ad9-8f2b-35e4a3428281';
export const vmeGlobalAdminGroup = 'aad-app-admpnl-vmeadmin-prod';
export const vmeGlobalAdminGroupId = '80eee2f3-d108-4cf3-8505-d93d9a698fef';
export const vmeSingleCompanyAdminGroup = 'aad-app-admpnl-gsadmin-prod';
export const vmeSingleCompanyAdminGroupId = '3193d9d0-36c4-4e31-ae6e-c011405301e9';

// Test group for local development
export const localAdminGroupForDevTest = 'vme-admin';
export const localAdminGroupForDevTestId = 'b9d13f54-7fe5-452c-8b1d-24db1e8c1875';

export interface BackendAccount {
  authenticate: (
    username: string,
    password: string,
    rememberMe?: boolean
  ) => AxiosPromise<{
    userName: string;
    userTypeName: string;
    companyId: string;
    userId: string;
    refresh_token: string;
  }>;

  getMyAccount: () => AxiosPromise<any>;
  logout: () => AxiosPromise;
  getVmeSupplierData: (id: string) => AxiosPromise;
  getVmeSupplierUmsatz: (data: any) => AxiosPromise;
  getVmeApiUserId: (email: string) => AxiosPromise;

  loadAzureUserId: (email: string) => AxiosPromise<any>;
  deactivateUserOnAzure: (id: string) => AxiosPromise<any>;
  activateUserOnAzure: (id: string) => AxiosPromise<any>;

  canSeeAllCompanyContacts: (id: string) => AxiosPromise<any>;
}

export const defaultBackendAccount: BackendAccount = {
  authenticate(
    username: string,
    password: string,
    rememberMe?: boolean
  ): AxiosPromise<{
    userName: string;
    userTypeName: string;
    companyId: string;
    userId: string;
    refresh_token: string;
  }> {
    const credentials: Credentials = {
      password,
      username,
      rememberMe,
      authorities: [],
      emailConfirmed: false,
      inactivityMinutes: 0,
    };
    return instance.post<any>(`${URLS.authenticate}`, credentials);
  },

  getMyAccount(): AxiosPromise<any> {
    return instance.get<any>(`${URLS.account}/GetMe`);
  },
  logout(): AxiosPromise {
    return instance.post(`${URLS.account}/logout`);
  },
  getVmeSupplierData(id:string): AxiosPromise {
    return instance.put(`${URLS.users}/GetVmeSupplier`, `"${id}"`);
  },
  getVmeSupplierUmsatz(data:any): AxiosPromise {
    return instance.put(`${URLS.users}/GetVmeSupplierUmsatz`, data);
  },
  getVmeApiUserId(email:string): AxiosPromise {
    return instance.put(`${URLS.users}/GetVmeUserIdByEmail`, `"${email}"`);
  },
  // getVmeApiUserId: (email: string) => AxiosPromise;

  loadAzureUserId(email: string): AxiosPromise<any> {
    return instance.get<any>(`${URLS.msGraphUsers}?$filter=mail eq '${email}'`);
  },
  deactivateUserOnAzure(azureUserId: string): AxiosPromise<any> {
    return instance.patch<any>(`${URLS.msGraphUsers}/${azureUserId}`, {
      accountEnabled: false,
    });
  },
  activateUserOnAzure(azureUserId: string): AxiosPromise<any> {
    return instance.patch<any>(`${URLS.msGraphUsers}/${azureUserId}`, {
      accountEnabled: true,
    });
  },
  canSeeAllCompanyContacts(azureUserId: string): AxiosPromise<any> {
    return instance.get<any>(
      `https://graph.microsoft.com/v1.0/me/memberOf/microsoft.graph.group?$count=true&$orderby=displayName&$search="displayName:${vmeGlobalAdminGroup}" OR "displayName:${vmeSupportGroup}" OR "displayName:${vmeSingleCompanyAdminGroup}"&$select=displayName,id`
    );
  },
};
