export enum ROUTES {
  //Used in `$router.push()`
  home = 'home',
  lists = 'lists',
  list = 'list',
  login = 'login',
  vmeSupplier = 'vmeSupplier',
  vmeKollege = 'vmeKollege',
  vmeBlock = 'vmeBlock',
  vmeUmsatz = 'vmeUmsatz',
  vmeUmsatzvergleich = 'vmeUmsatzvergleich',
  export = 'export',
  archive = 'archive',

  gspRoles = 'roles',
  gspRole = 'role',
  // ROUTES.gspRole
}