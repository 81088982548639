
import { MutationTree } from 'vuex';
import { GspUserRoleState } from './types';
import Vue from 'vue';
import gspUserRole, { GspUserRole, Value } from '@/shared/model/gspUserRole';

export const mutations: MutationTree<GspUserRoleState> = {
  setGspUserRoles(state, payload: Value) {
    state.gspUserRoles.items = payload.value.map((x) => gspUserRole.parse(x));
    state.gspUserRoles.total = payload['@odata.count'] || 0;
    state.gspUserRoles.isLoading = false;
  },
  setGspUserRolesTotal(state, payload: number) {
    state.gspUserRoles.total = payload;
  },
  setGspUserRolesIsLoading(state, payload: boolean) {
    state.gspUserRoles.isLoading = payload;
  },
  addRoleFromBackend(state, payload: any) {
    console.log('payload :>> ', payload);
    state.gspUserRoles.items.push(payload);
  },
  addRoleDetailFromBackend(state, payload: any) {
   let userRole = state.gspUserRoles.items.find((x:any) => x.id == payload.gspUserRoleId)
   if (userRole) {
     let userRoleDetailIndex = userRole!.gspUserRoleDetails.findIndex((x:any) => x.serviceId == payload.serviceId);
     if (userRoleDetailIndex < 0) {
      userRole!.gspUserRoleDetails.push(payload);
     } else {
      userRole!.gspUserRoleDetails.splice(userRoleDetailIndex, 1,payload);
     }

   }
  },
};
