import { GetterTree } from 'vuex';
import { ContactState } from './types';
import { RootState } from '../../types';
import store from '../../index';

export const getters: GetterTree<ContactState, RootState> = {
  ['getContactForLoggedInUser']: (state) => {
    return state.loggedInContact;
  },
  ['getContacts']: (state) => state.contacts,
  ['getContactsIsLoading']: (state) => state.contacts?.isLoading,
  ['getContactsTotal']: (state) => state.contacts?.total,
  ['getContactsSearchParams']: (state) => state.contacts?.searchParams,
  ['getSearchData']: (state) => state.searchData,
};
