export interface SearchData {
  isActive: boolean | null;
  companyZr: string[];
  isUserInAzureAdminGroup: boolean;
}

function defaultData(): SearchData {
  return {
    isActive: true,
    companyZr: [],
    isUserInAzureAdminGroup: false,
  };
}

export default {
  defaultData,
};
